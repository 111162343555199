import { SKILL_PROCESS_SYSTEM_ADDRESS } from "../../constant";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { erc20Abi } from "viem";
import {
  useAccount,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { MaxUint256 } from "ethers";
import { TxToast } from "../ui/shared/TxToast";
import { AppSpinner } from "../ui/shared/AppSpinner";
import { singletonEntity } from "@latticexyz/store-sync/recs";
import { useMUD } from "../../useMUD";
import { useComponentValue } from "@latticexyz/react";

export function useAllowance(runOnInit = false) {
  const { address } = useAccount();
  const {
    networkLayer: {
      components: { EnergyToken },
    },
    energy: { balance },
  } = useMUD();
  const token = useComponentValue(EnergyToken, singletonEntity);

  const { data: allowanceAmount = null, refetch } = useReadContract({
    address: token?.tokenAddress as `0x${string}`,
    abi: erc20Abi,
    functionName: "allowance",
    args: [
      address as `0x${string}`,
      SKILL_PROCESS_SYSTEM_ADDRESS as `0x${string}`,
    ],
  });

  const {
    writeContract,
    data: hash,
    error,
    isPending,
    status,
    reset,
  } = useWriteContract();
  const {
    isLoading: isConfirming,
    isSuccess,
    error: txError,
    data: receipt,
  } = useWaitForTransactionReceipt({ hash });

  const writeAllowance = () =>
    writeContract({
      address: token?.tokenAddress as `0x${string}`,
      abi: erc20Abi,
      functionName: "approve",
      args: [SKILL_PROCESS_SYSTEM_ADDRESS as `0x${string}`, balance * 10 ** 18],
    });

  //Toast effects for Allowance
  useEffect(() => {
    if (error) {
      toast.custom(
        <TxToast
          title="Confirmation failed"
          description={error.message.split(".")[0]}
          status="failed"
        />,
        { id: "token-approve-wallet-failed" }
      );
    }
    if (isPending) {
      toast.custom(
        <TxToast
          title="Confirm your Approval"
          logo={<AppSpinner wrapperWidth="w-fit" />}
          description="Waiting to confirm..."
          status="pending"
        />,
        { id: "token-approve-wallet-pending" }
      );
    }
    // if (isSuccess) {
    //   toast.custom(
    //     <TxToast
    //       title="Txn Sent Success"
    //       description="Waiting for receipt..."
    //       status="success"
    //     />,
    //     { id: "token-approve-wallet-success" },
    //   )
    // }

    if (txError) {
      toast.custom(
        <TxToast
          title="Failed to Approve"
          description={txError.message.split(".")[0]}
          status="failed"
        />,
        { id: "token-approve-failed" }
      );
    }
    if (isConfirming) {
      toast.custom(
        <TxToast
          title="Pending Network"
          logo={<AppSpinner wrapperWidth="w-fit" />}
          description="Getting approval transaction..."
          status="pending"
        />,
        { id: "token-approve-pending" }
      );
    }
    if (isSuccess) {
      refetch();
      toast.custom(
        <TxToast
          title={
            <>
              Approved
              <a
                href={`https://testnet.storyscan.xyz/tx/${receipt.transactionHash}`}
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-box-arrow-up-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                  />
                  <path
                    fillRule="evenodd"
                    d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                  />
                </svg>
              </a>
            </>
          }
          description="You have approved the token"
          status="success"
        />,
        { id: "token-approve-success" }
      );

      //reset time after 5 sec
      setTimeout(() => {
        reset();
      }, 5000);
    }
  }, [
    error,
    isConfirming,
    isPending,
    isSuccess,
    receipt,
    refetch,
    reset,
    txError,
  ]);

  useEffect(() => {
    if (
      runOnInit &&
      balance > 0 &&
      allowanceAmount !== null &&
      Number(allowanceAmount) < 100
    )
      writeAllowance();
  }, [allowanceAmount, balance]);

  return { allowanceAmount: Number(allowanceAmount), writeAllowance, status };
}
