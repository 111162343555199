import { ABORT_MAP } from "../constant";

export function formatSui(amount: string): number {
  // Check if the input is a valid number string
  if (!/^\d+$/.test(amount)) throw new Error("Invalid input: must be a number or numeric string");

  // Convert to a number after dividing by 10^9
  const formattedAmount = parseFloat(amount) / Math.pow(10, 9);

  return Number(formattedAmount.toFixed(2));
}

export function formatTimeFromSec(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const pad = (num: number) => num.toString().padStart(2, "0");

  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
}

export function formatResource(resources: any[]) {
  const result = { ore: 0, wood: 0, seed: 0 };

  resources.forEach((resource) => {
    if (resource.inventoryItemId === 2000000003) result.ore = resource.inventoryQuantity;
    else if (resource.inventoryItemId === 2000000001) result.wood = resource.inventoryQuantity;
    else if (resource.inventoryItemId === 2) result.seed = resource.inventoryQuantity;
  });

  return result;
}

export function formatInventory(inventory: any[]) {
  const result = { copper: 0, tree: 0, cotton: 0 };

  inventory.forEach((inv: any) => {
    if (inv.inventoryItemId === 301) result.copper = inv.inventoryQuantity;
    else if (inv.inventoryItemId === 200) result.tree = inv.inventoryQuantity;
    else if (inv.inventoryItemId === 102) result.cotton = inv.inventoryQuantity;
  });

  return result;
}

export function mapResource(resourceItemId: number[], resourceQuantity: number[]) {
  const result = { ore: 0, wood: 0, seed: 0, copper: 0, tree: 0, cotton: 0 };

  resourceItemId.forEach((resourceId, index) => {
    if (resourceId === 2000000003) result.ore = resourceQuantity[index];
    else if (resourceId === 2000000001) result.wood = resourceQuantity[index];
    else if (resourceId === 2) result.seed = resourceQuantity[index];
    else if (resourceId === 301) result.ore = resourceQuantity[index];
    else if (resourceId === 200) result.wood = resourceQuantity[index];
    else if (resourceId === 102) result.seed = resourceQuantity[index];
  });

  return result;
}

export function calculateTimeLeftInSec(start: string, duration: string): number {
  let startTimestamp = Number(start);
  const durationInNumber = Number(duration);

  const currentTimestamp = Date.now();

  // Ensure startTimestamp is in milliseconds
  if (startTimestamp < 1e12) startTimestamp *= 1000;

  // Calculate the end timestamp
  const endTimestamp = startTimestamp + durationInNumber * 1000;

  // Calculate the remaining time in seconds
  const remainingTimeInMillis = endTimestamp - currentTimestamp;
  const remainingTimeInSec = Math.floor(remainingTimeInMillis / 1000);

  // Return the remaining time in seconds, or 0 if the result is negative
  return remainingTimeInSec > 0 ? remainingTimeInSec : 0;
}

export function truncateString(input: string): string {
  // Check if the input string is longer than 10 characters
  if (input.length <= 10) return input;

  // Get the first 6 characters
  const start = input.substring(0, 6);

  // Get the last 4 characters
  const end = input.substring(input.length - 4);

  // Combine the parts with '...' in the middle
  return `${start}...${end}`;
}

export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function transferErrIntoReadableMsg(errMsg: string): string {
  console.log(errMsg);
  const identifierRegex = /The contract function\s+"([^"]+)"\s+reverted with the following signature:\s*0x([0-9a-fA-F]+)/;
  const codeRegex = /reverted with the following reason:\s*([^\n]+)/;

  

  const identifierMatch = errMsg.match(identifierRegex);
  const codeMatch = errMsg.match(codeRegex);
  console.log("codematch",codeMatch);
  

  const identifier = identifierMatch ? identifierMatch[1] : undefined;
  const code = identifierMatch ? identifierMatch[2] : undefined;
  console.log("Error identifier",{identifier,code});
  // Handle common errors like insufficient balance, gas fee, etc.
  if (errMsg.includes("No valid gas coins found for the transaction.")) return errMsg;
  // console.log( ABORT_MAP[identifier], ABORT_MAP[identifier][code]);
  // Handle specific errors like something went wrong with the transaction but we don't know what
  if (!(identifier && code && ABORT_MAP[identifier] && ABORT_MAP[identifier][code])){
    if(codeMatch) return codeMatch[1];
    return "An unknown error occurred, please try again later. If the issue persists, contact the administrator";
  }
    

  // Handle known errors with specific messages
  const abortCode = ABORT_MAP[identifier][code];
  return abortCode
    .replace(/([A-Z])/g, " $1") // Add a space before each uppercase letter
    .trim() // Trim any leading/trailing whitespace
    .toLowerCase()
    .replace(/^\w/, (c) => c.toUpperCase());
}

export const seedRandom = () => {
  let seed = 1.247938;
  return () => {
    const x = Math.sin(seed++) * 10000;
    seed = x - Math.floor(x);
    return seed;
  };
}

export type ValueOf<T> = T[keyof T];
