import './index.css'
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Providers } from "./app/context/Providers";
import { router } from "./router";
import {AppToaster} from "./app/ui/shared/AppToaster";

const rootElement = document.getElementById("react-root");
if (!rootElement) throw new Error("React root not found");
const root = ReactDOM.createRoot(rootElement);

root.render(
    <Providers>
      <RouterProvider router={router} />
      <AppToaster />
    </Providers>
  );