import { useEffect } from "react";
import { NetworkLayer } from "../../layers/network/createNetworkLayer";
import { useStore } from "../../useStore";
import { usePhaserLayer } from "../hooks/usePhaserLayer";

type Props = {
  networkLayer: NetworkLayer | null;
};

export const PhaserLayer = ({ networkLayer }: Props) => {
  const { ref: phaserRef, phaserLayer } = usePhaserLayer({ networkLayer });

  useEffect(() => {
    if (phaserLayer) {
      useStore.setState({ phaserLayer });
    }
  }, [phaserLayer]);

  return (
    <div
      ref={phaserRef}
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  );
};
