import { defineSystem, getComponentValue, getComponentValueStrict, runQuery } from "@latticexyz/recs";
import { useStore } from "./useStore";

export const useMUD = () => {
  const { networkLayer, phaserLayer, player, energy, externalWalletClient, writeWorldContract, clickedIsland } =
    useStore();

  if (networkLayer === null || phaserLayer === null) {
    throw new Error("Store not initialized");
  }

  if ((window as any).layers === undefined) {
    (window as any).layers = {
      networkLayer,
      phaserLayer,
    };

    (window as any).components = {
      ...networkLayer.components,
      ...phaserLayer.components,
    };

    (window as any).ecs = {
      getComponentValue,
      getComponentValueStrict,
      runQuery,
      defineSystem,
    };
  }

  return {
    networkLayer,
    phaserLayer,
    externalWalletClient,
    writeWorldContract,
    player,
    energy,
    clickedIsland
  };
};
