import { useState, useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { IShip } from "../../interface";
import { useMUD } from "../../../useMUD";
import { Has, HasValue, getComponentValueStrict } from "@latticexyz/recs";
import { useEntityQuery } from "@latticexyz/react";
import { decodeEntity } from "@latticexyz/store-sync/recs";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "@hello-pangea/dnd";
import AppLoading from "../shared/AppLoading";
import { formatInventory, truncateString } from "../../../utils/tools";
import TransferShipForm from "../form/TransferShipForm";
import { ITEM_CODE_LIST, ROSTER_STATUS } from "../../../constant";
import { getLocalCoordinate } from "../../../utils/math";
import TransferResourceForm from "../form/TransferResourceForm";
import SortRosterForm from "../form/SortRosterForm";

export interface IShipHere extends IShip {
  iconUrl: string;
  name: string;
  status: number;
}

// Constants
const shipTags = [
  { id: 0, title: "All" },
  { id: 1, title: "Roster1" },
  { id: 2, title: "Roster2" },
  { id: 3, title: "Roster3" },
  { id: 4, title: "Roster4" },
  { id: 5, title: "Unassigned" },
];

export default function ShipsMenu({
  closeShipsMenu,
}: {
  closeShipsMenu: () => void;
}) {
  const {
    networkLayer: {
      components: { Roster, Ship, PlayerInventory, ShipInventory },
    },
    phaserLayer: {
      scenes: {
        Main: {
          camera: { phaserCamera },
        },
      },
    },
    player,
  } = useMUD();
  // Rosters to display
  const [allRoster, setAllRoster] = useState<IShipHere[]>([]);
  const [roster1, setRoster1] = useState<IShipHere[]>([]);
  const [roster2, setRoster2] = useState<IShipHere[]>([]);
  const [roster3, setRoster3] = useState<IShipHere[]>([]);
  const [roster4, setRoster4] = useState<IShipHere[]>([]);
  const [unassignedRoster, setUnassignedRoster] = useState<IShipHere[]>([]);
  // const [currentTag, setCurrentTag] = useState<number>(0); // 0, 1, 2, 3, 4, 5
  const [pending, setPending] = useState<boolean>(false);
  const [shipIdsOrder, setShipIdsOrder] = useState<bigint[]>([]);

  const [currentTag, setCurrentTag] = useState<number>(0); // 0, 1, 2, 3, 4, 5

  // Flags
  const [shipInventoryFlag, setShipInventoryFlag] = useState<boolean>(false);

  // Inventory transferring
  const [transferRosterSequenceNumber, settransferRosterSequenceNumber] =
    useState<string>("");
  const [col1ShipId, setCol1ShipId] = useState<string>("");
  const [col1IconUrl, setCol1IconUrl] = useState<string>("");
  const [col1Name, setCol1Name] = useState<string>("");
  const [col1Inventory, setCol1Inventory] = useState<any[]>([]);
  const [col2ShipId, setCol2ShipId] = useState<string>("");
  const [col2Options, setCol2Options] = useState<string[]>([]);
  const [col2IconUrl, setCol2IconUrl] = useState<string>("");
  const [col2Name, setCol2Name] = useState<string>("");
  const [col2Inventory, setCol2Inventory] = useState<any[]>([]);
  const [transferPath, setTransferPath] = useState<any[]>([]);

  const RosterEntities = useEntityQuery([Has(Roster)]);

  const PlayerRosterInfo = RosterEntities.map((entity) => {
    const { playerId, sequenceNumber } = decodeEntity(
      { playerId: "uint256", sequenceNumber: "uint32" },
      entity
    );
    if (Number(playerId) === player?.id) {
      const {
        status,
        speed,
        baseExperience,
        environmentOwned,
        updatedCoordinatesX,
        updatedCoordinatesY,
        coordinatesUpdatedAt,
        targetCoordinatesX,
        targetCoordinatesY,
        originCoordinatesX,
        originCoordinatesY,
        sailDuration,
        setSailAt,
        currentSailSegment,
        shipBattleId,
        shipIds,
      } = getComponentValueStrict(Roster, entity);
      return {
        sequenceNumber,
        status,
        speed,
        baseExperience,
        environmentOwned,
        updatedCoordinatesX,
        updatedCoordinatesY,
        coordinatesUpdatedAt,
        targetCoordinatesX,
        targetCoordinatesY,
        originCoordinatesX,
        originCoordinatesY,
        sailDuration,
        setSailAt,
        currentSailSegment,
        shipBattleId,
        shipIds,
      };
    }
    return null;
  }).filter((value) => value !== null);

  console.log({PlayerRosterInfo});

  //since the roster will be from 0-4 with correct sequence number
  const getRosterPosition = PlayerRosterInfo.map((roster) => {
    return {
      updatedCoordinates:{
        x: roster.updatedCoordinatesX,
        y: roster.updatedCoordinatesY},
      updatedSailSegment: roster.currentSailSegment,
      updatedAt: Number(roster.coordinatesUpdatedAt)
    };
  });

  console.log({ getRosterPosition });
  const ShipEntities = useEntityQuery([
    HasValue(Ship, { playerId: BigInt(player?.id) }),
  ]);
  const shipInfo = ShipEntities.map((entity) => {
    const { id } = decodeEntity({ id: "uint256" }, entity);
    const { attack, rosterSequenceNumber, protection, speed, healthPoints } =
      getComponentValueStrict(Ship, entity);
    const shipInventoryEntities = useEntityQuery([Has(ShipInventory)]);
    const inventory = shipInventoryEntities
      .map((shipEntity) => {
        const { shipId, inventoryIndex } = decodeEntity(
          { shipId: "uint256", inventoryIndex: "uint64" },
          shipEntity
        );
        console.log({ inventoryIndex });
        if (shipId === id) {
          const { inventoryItemId, inventoryQuantity } =
            getComponentValueStrict(ShipInventory, shipEntity);
          return {
            inventoryItemId,
            inventoryQuantity,
          };
        }
        return null;
      })
      .filter((data) => data);
    return {
      id,
      attack,
      rosterSequenceNumber,
      protection,
      speed,
      healthPoints,
      inventory,
    };
  });
  console.log({shipInfo});
  async function initRosters() {
    setPending(true);

    const allShips:IShipHere[] = [];

    for (let i = 0; i < PlayerRosterInfo.length; i++) {
      if(PlayerRosterInfo[i].shipIds.length>0){
        const ships:IShipHere[] = [];
        //for showing the ship in sequence they arranged in the roster
        PlayerRosterInfo[i].shipIds.forEach((shipId,j)=>{
          console.log(shipId);
          const ship = shipInfo.filter((ship)=> ship.id === shipId);
          console.log({ship});
          ships.push({
            status: PlayerRosterInfo[i].status,
            iconUrl: "/frontend/resource/ship-sm.png",
            name: `R-${i}, Ship-${j + 1}`,
            ...ship[0],
          });
          allShips.push({
            status: PlayerRosterInfo[i].status,
            iconUrl: "/frontend/resource/ship-sm.png",
            name: `R-${i}, Ship-${j + 1}`,
            ...ship[0],
          });
        });
        if (i === 0) setUnassignedRoster(ships);
        else if (i === 1) setRoster1(ships);
        else if (i === 2) setRoster2(ships);
        else if (i === 3) setRoster3(ships);
        else if (i === 4) setRoster4(ships);
      }
    }

    setAllRoster(allShips);
    setPending(false);
  }
  useEffect(() => {
    initRosters();
  }, [ShipEntities, RosterEntities]);
  function handleOnDragEnd(result: DropResult) {
    if (!result.destination) return;

    const newOrder = rosterToRender();
    const [reorderedItem] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, reorderedItem);

    const shipIds = newOrder.map((ship) => ship.id);
    setShipIdsOrder(shipIds);
  }

  const rosterToRender = useCallback(() => {
    if (currentTag === 0) return allRoster;
    else if (currentTag === 1) return roster1;
    else if (currentTag === 2) return roster2;
    else if (currentTag === 3) return roster3;
    else if (currentTag === 4) return roster4;
    else if (currentTag === 5) return unassignedRoster;
    return [];
  }, [
    allRoster,
    currentTag,
    roster1,
    roster2,
    roster3,
    roster4,
    unassignedRoster,
  ]);

  //for ships inventory
  const InventoryEntities = useEntityQuery([
    Has(PlayerInventory),
    // HasValue(PlayerInventory, {playerId: player?.id}),
  ]);

  const playerInventory = InventoryEntities.map((entity) => {
    const { playerId } = decodeEntity(
      { playerId: "uint256", inventoryIndex: "uint64" },
      entity
    );
    const { inventoryItemId, inventoryQuantity } = getComponentValueStrict(
      PlayerInventory,
      entity
    );
    if (Number(playerId) !== player?.id) return null;
    return {
      inventoryItemId,
      inventoryQuantity,
    };
  }).filter((data) => data);

  function handleShipInventory(ship: IShipHere) {
    setShipInventoryFlag(true);
    settransferRosterSequenceNumber(ship.rosterSequenceNumber.toString());
    setCol1ShipId(ship.id.toString());
    prepareInventory(ship.rosterSequenceNumber, ship.id.toString(), "INIT");
  }
  function prepareInventory(
    rosterId: string,
    col1ShipId: string,
    col2Code: string
  ) {
    const roster = [unassignedRoster, roster1, roster2, roster3, roster4].find(
      (roster) => roster[0] && roster[0].rosterSequenceNumber === rosterId
    );
    if (!roster) return;

    const options = roster
      .filter((ship) => ship.id.toString() !== col1ShipId)
      .map((ship) => ship.name);
    setCol2Options([...options, "Your Island"]);

    // For col1
    const col1Ship = roster.find((ship) => ship.id.toString() === col1ShipId);
    if (!col1Ship) return;

    const {
      copper: col1Copper,
      tree: col1Tree,
      cotton: col1Cotton,
    } = formatInventory(col1Ship.inventory);
    const col1Intentory = ITEM_CODE_LIST.map((itemId) => {
      if (itemId === 301)
        return {
          itemId,
          iconUrl: "/frontend/resource/copper.png",
          quantity: col1Copper,
        };
      else if (itemId === 200)
        return {
          itemId,
          iconUrl: "/frontend/resource/tree.png",
          quantity: col1Tree,
        };
      else if (itemId === 102)
        return {
          itemId,
          iconUrl: "/frontend/resource/cotton.png",
          quantity: col1Cotton,
        };
    });
    setCol1IconUrl(col1Ship.iconUrl);
    setCol1Name(col1Ship.name);
    setCol1Inventory(col1Intentory);

    // For col2
    let col2Copper = 0;
    let col2Tree = 0;
    let col2Cotton = 0;

    // If col2 is the player's island
    if (col2Code === "Your Island" || options.length === 0) {
      const { copper, tree, cotton } = formatInventory(playerInventory);
      col2Copper = copper;
      col2Tree = tree;
      col2Cotton = cotton;

      setCol2Name("Your Island");
      setCol2IconUrl("/assets/images/island.png");
    }
    // Else if col2 is a ship
    else {
      const col2Ship =
        col2Code === "INIT"
          ? roster.find((ship) => ship.name === options[0])
          : roster.find((ship) => ship.name === col2Code);
      if (!col2Ship) return;

      const { copper, tree, cotton } = formatInventory(col2Ship.inventory);
      col2Copper = copper;
      col2Tree = tree;
      col2Cotton = cotton;

      setCol2ShipId(col2Ship.id.toString());
      setCol2Name(col2Code === "INIT" ? col2Ship.name : col2Code);
      setCol2IconUrl(col2Ship.iconUrl);
    }

    const col2Intentory = ITEM_CODE_LIST.map((itemId) => {
      if (itemId === 301)
        return {
          itemId,
          iconUrl: "/frontend/resource/copper.png",
          quantity: col2Copper,
        };
      else if (itemId === 200)
        return {
          itemId,
          iconUrl: "/frontend/resource/tree.png",
          quantity: col2Tree,
        };
      else if (itemId === 102)
        return {
          itemId,
          iconUrl: "/frontend/resource/cotton.png",
          quantity: col2Cotton,
        };
    });
    setCol2Inventory(col2Intentory);

    // Init col inventory quantites path
    const path = ITEM_CODE_LIST.map((itemId) => {
      return { itemId, quantity: 0 };
    });
    setTransferPath(path);
  }

  //transfer of ship inventory
  function handleInventoryResource(
    col: number,
    itemId: number,
    transferQuantity = 1
  ) {
    if (col === 1) {
      if (
        col1Inventory.some((inv) => itemId === inv.itemId && inv.quantity < 1)
      )
        return;

      setCol1Inventory((prev) =>
        prev.map((inv) =>
          itemId === inv.itemId
            ? { ...inv, quantity: inv.quantity - transferQuantity }
            : inv
        )
      );
      setCol2Inventory((prev) =>
        prev.map((inv) =>
          itemId === inv.itemId
            ? { ...inv, quantity: inv.quantity + transferQuantity }
            : inv
        )
      );
      setTransferPath((prev) =>
        prev.map((path) =>
          itemId === path.itemId
            ? { ...path, quantity: path.quantity + transferQuantity }
            : path
        )
      );
    } else if (col === 2) {
      if (
        col2Inventory.some((inv) => itemId === inv.itemId && inv.quantity < 1)
      )
        return;

      setCol1Inventory((prev) =>
        prev.map((inv) =>
          itemId === inv.itemId
            ? { ...inv, quantity: inv.quantity + transferQuantity }
            : inv
        )
      );
      setCol2Inventory((prev) =>
        prev.map((inv) =>
          itemId === inv.itemId
            ? { ...inv, quantity: inv.quantity - transferQuantity }
            : inv
        )
      );
      setTransferPath((prev) =>
        prev.map((path) =>
          itemId === path.itemId
            ? { ...path, quantity: path.quantity - transferQuantity }
            : path
        )
      );
    }
  }
  //for the transfer of all resources
  function handleAllInventoryResource(col: number) {
    if (col === 1) {
      if (col1Inventory.every((inv) => inv.quantity < 1)) return;
      col1Inventory.forEach((inv) =>
        handleInventoryResource(col, inv.itemId, inv.quantity)
      );
    } else if (col === 2) {
      if (col2Inventory.every((inv) => inv.quantity < 1)) return;
      col2Inventory.forEach((inv) =>
        handleInventoryResource(col, inv.itemId, inv.quantity)
      );
    }
  }

  return (
    <>
      <div className="fixed w-full h-screen flex justify-center items-center">
        <div className="w-2/3 flex justify-center items-center">
          {!shipInventoryFlag ? (
            // Rosters menu
            <div className="w-full relative flex flex-col bg-paper-row bg-center bg-no-repeat bg-[length:100%_100%] lg:px-12 pt-4 pb-16 gap-4">
              <div className="flex justify-end ">
                <img
                  className="w-[42px] h-[42px]"
                  onClick={closeShipsMenu}
                  src="/frontend/icon/x-mark.png"
                  alt="x-icon"
                />
              </div>

              {/* Tags */}
              <div className="w-full flex justify-between items-center">
                {shipTags.map((data) => (
                  <div
                    key={data.id}
                    className={`flex justify-center items-center text-white max-lg:text-sm cursor-pointer bg-frame-roster bg-center bg-no-repeat bg-[length:100%_100%] px-8 py-2 ${
                      currentTag === data.id && "!text-red-400"
                    }`}
                    onClick={() => setCurrentTag(data.id)}
                  >
                    {data.title}
                  </div>
                ))}
              </div>
              {/* Rosters */}
              <div className="w-full min-h-96 max-h-[400px] flex flex-col overflow-y-auto gap-4 scrollbar mr-2">
                {!pending ? (
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="ships" direction="vertical">
                      {(provided) => (
                        <div
                          className="flex flex-col gap-2"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {rosterToRender().map((ship, index) => (
                            <Draggable
                              key={ship.id}
                              draggableId={ship.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="w-full h-[120px] flex justify-between items-center bg-frame-ship bg-center bg-no-repeat bg-[length:100%_100%]"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="relative w-1/6 h-full flex justify-center items-center ml-3">
                                    <div className="absolute top-2 left-3 text-white text-2xl">
                                      {index + 1}
                                    </div>
                                    <div className="w-1/2">
                                      <img
                                        className="w-[48px] h-[48px]"
                                        src={ship.iconUrl}
                                        alt="ship-icon"
                                      />
                                    </div>
                                  </div>
                                  <div className="h-full">
                                    <img
                                      className=" w-[5px] h-[100px]"
                                      src="/frontend/item/ship-line-col.png"
                                      alt="ship-line"
                                    />
                                  </div>

                                  <div className="flex-1 flex justify-between items-center text-white px-6 py-1">
                                    <div className="flex-1 flex flex-col justify-center items-start gap-1">
                                      <p className="text-xl line-clamp-1">
                                        Name: {ship.name}
                                      </p>
                                      <p>
                                        ID: {truncateString(ship.id.toString())}
                                      </p>
                                      <p>HP: {ship.healthPoints}</p>
                                    </div>

                                    <div className="flex-1 flex justify-between items-center">
                                      {[
                                        {
                                          count: ship.attack,
                                          iconUrl: "/frontend/icon/attack.png",
                                        },
                                        {
                                          count: ship.protection,
                                          iconUrl: "/frontend/icon/defense.png",
                                        },
                                        {
                                          count: ship.speed,
                                          iconUrl: "/frontend/icon/speed.png",
                                        },
                                      ].map((attr, index) => (
                                        <div
                                          key={index}
                                          className="h-16 relative flex justify-center items-center"
                                        >
                                          <img
                                            className="w-[48px] h-[48px]"
                                            src={attr.iconUrl}
                                            alt="attr-icon"
                                          />
                                          <p className="absolute bottom-0 -right-2 text-3xl text-white">
                                            {attr.count}
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="h-full">
                                    <img
                                      className="w-[5px] h-[100px]"
                                      src="/frontend/item/ship-line-col.png"
                                      alt="ship-line-col-icon"
                                    />
                                  </div>

                                  <div className="w-1/6 h-full relative flex justify-center items-center text-white text-2xl cursor-pointer">
                                    {PlayerRosterInfo.length > 0 &&
                                      [1, 2, 3, 4].includes(currentTag) && (
                                        // Removing ships
                                        <TransferShipForm
                                          unassignedRoster={unassignedRoster}
                                          toRosterSequenceNumber={0}
                                          toPosition={0}
                                          actionFlag={false}
                                          removeShip={ship}
                                        />
                                      )}

                                    <div
                                      onClick={() => handleShipInventory(ship)}
                                    >
                                      <p>Transfer</p>
                                      <p>Inventory</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <AppLoading size={150} />
                )}
                {PlayerRosterInfo.length > 0 &&
                  [1, 2, 3, 4].includes(currentTag) && (
                    // Adding ships
                    <TransferShipForm
                      unassignedRoster={unassignedRoster}
                      toRosterSequenceNumber={
                        PlayerRosterInfo[currentTag].sequenceNumber
                      }
                      toPosition={rosterToRender().length}
                      actionFlag={true}
                    />
                  )}
              </div>
              {rosterToRender().length > 0 &&
                PlayerRosterInfo.length > 0 &&
                [1, 2, 3, 4].includes(currentTag) && (
                  <div className="flex justify-between items-center">
                    <div>
                      {rosterToRender()[0].status ===
                        ROSTER_STATUS.DESTROYED && (
                        <span className="text-red-400 font-bold p-2 text-right text-xl bg-black/90">
                          Oops, Your Roster is destroyed by enemy roster!
                        </span>
                      )}
                    </div>

                    <div className="flex items-center gap-2">
                      <SortRosterForm rosterSequenceNumber={PlayerRosterInfo[currentTag].sequenceNumber} newRosterShipIds={shipIdsOrder} />

                      <button
                        className="w-[144px] text-center text-white text-2xl cursor-pointer bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] py-2 disabled:opacity-80 disabled:cursor-not-allowed"
                        type="button"
                        disabled={
                          rosterToRender()[0].status === ROSTER_STATUS.DESTROYED
                        }
                        onClick={() => {
                          const rosterPosition =getRosterPosition[
                              rosterToRender()[0].rosterSequenceNumber
                            ];
                          
                          const localCoordinate = getLocalCoordinate(
                            rosterPosition.updatedCoordinates.x,
                            rosterPosition.updatedCoordinates.y
                          );
                          phaserCamera
                            .centerOn(localCoordinate.x, localCoordinate.y)
                            .zoomTo(1, 2000, "Sine.easeInOut", true);
                          closeShipsMenu();
                        }}
                      >
                        Locate
                      </button>

                      <button
                        className="w-[144px] text-center text-white text-2xl cursor-pointer bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] py-2 disabled:opacity-80 disabled:cursor-not-allowed"
                        type="button"
                        disabled={
                          rosterToRender()[0].status === ROSTER_STATUS.DESTROYED
                        }
                        onClick={() => {}}
                      >
                        Sail
                      </button>
                    </div>
                  </div>
                )}
            </div>
          ) : (
            // Ships inventory menu
            <div className="flex flex-col items-center gap-6">
              <div className="flex justify-between items-center gap-2">
                {[1, 0, 2].map((col) => (
                  <>
                    {col === 0 && (
                      <div className=" flex flex-col gap-4">
                        <button
                          className="h-12 text-white flex justify-center items-center gap-1 cursor-pointer bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] px-4 text-lg py-1"
                          type="button"
                          onClick={() => handleAllInventoryResource(1)}
                        >
                          Transfer All
                          <img
                            className="w-[24px] h-[24px]"
                            src="/frontend/icon/transfer-right.png"
                            alt="transfer-right-icon"
                          />
                        </button>
                        <img
                          className="w-[128px] h-[128px]"
                          src="/frontend/icon/exchange.png"
                          alt="exchange-icon"
                        />
                        <button
                          className="h-12  text-white flex justify-center items-center gap-1 cursor-pointer bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] px-4 text-lg py-1"
                          type="button"
                          onClick={() => handleAllInventoryResource(2)}
                        >
                          Transfer All
                          <img
                            className="w-[24px] h-[24px]"
                            src="/frontend/icon/transfer-left.png"
                            alt="transfer-left-icon"
                          />
                        </button>
                      </div>
                    )}

                    {[1, 2].includes(col) && (
                      <div
                        key={col}
                        className="flex flex-col items-center gap-2"
                      >
                        <div className="flex justify-between items-center gap-2">
                          {col === 1 ? (
                            <>
                              <div className="w-[80px] aspect-[1] flex justify-center items-center bg-frame-normal bg-center bg-no-repeat bg-[length:100%_100%]">
                                {col1IconUrl && (
                                  <img
                                    className="w-1/2"
                                    src={col1IconUrl}
                                    alt="ship-icon"
                                    width="80"
                                    height="80"
                                    loading="eager"
                                  />
                                )}
                              </div>

                              <div className="w-[160px] flex justify-center items-center bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] text-white text-center leading-none py-4">
                                {col1Name}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="w-[80px] aspect-[1] flex justify-center items-center bg-frame-normal bg-center bg-no-repeat bg-[length:100%_100%]">
                                {col2IconUrl && (
                                  <img
                                    className="w-1/2"
                                    src={col2IconUrl}
                                    alt="ship-icon"
                                    width={80}
                                    height={80}
                                    loading="eager"
                                  />
                                )}
                              </div>

                              {/* Select-option */}
                              <div className="relative">
                                <select
                                  className="relative w-[160px] flex justify-center items-center bg-blue-500 bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] text-white text-center leading-none appearance-none outline-none cursor-pointer py-4 gap-2"
                                  onChange={(e) =>
                                    prepareInventory(
                                      transferRosterSequenceNumber,
                                      col1ShipId,
                                      e.target.value
                                    )
                                  }
                                >
                                  {col2Options.map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>

                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
                                  <svg
                                    className="w-4 h-4"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M19 9l-7 7-7-7"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="w-[320px] min-h-[400px] flex flex-col items-center bg-paper-col bg-center bg-no-repeat bg-[length:100%_100%] px-12 py-8 gap-4">
                          <div className="w-full text-lg text-white text-center bg-frame-text bg-center bg-no-repeat bg-[length:100%_100%] py-3">
                            {col === 2 && col2Name === "Your Island"
                              ? "Island Inventory"
                              : "Ship Inventory"}
                          </div>

                          <div className="w-full grid grid-cols-2 gap-2">
                            {(col === 1 ? col1Inventory : col2Inventory).map(
                              ({ itemId, iconUrl, quantity }) => (
                                <div
                                  key={itemId}
                                  className="w-full aspect-[1] relative flex justify-center items-center bg-center bg-no-repeat bg-frame-normal bg-[length:100%_100%] border-2 border-transparent rounded-md text-white cursor-pointer"
                                  onClick={() =>
                                    handleInventoryResource(col, itemId)
                                  }
                                >
                                  <img
                                    src={iconUrl}
                                    alt="tree-icon"
                                    width={48}
                                    height={48}
                                    loading="eager"
                                  />
                                  <span className="absolute bottom-1.5 right-4">
                                    {quantity}
                                  </span>
                                </div>
                              )
                            )}

                            {[0, 1, 2, 3, 4].map((id) => (
                              <div
                                key={id}
                                className="w-full aspect-[1] bg-center bg-no-repeat bg-frame-normal bg-[length:100%_100%] border-2 border-transparent rounded-md text-white cursor-pointer"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>

              <div className="flex items-center text-2xl font-medium gap-4">
                <button
                  className="h-12 text-white flex justify-center items-center cursor-pointer bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] px-8 py-1"
                  type="button"
                  onClick={() => setShipInventoryFlag(false)}
                >
                  Back
                </button>

                <TransferResourceForm
                  getRosterPosition={getRosterPosition}
                  rosterSequenceNumber={Number(transferRosterSequenceNumber)}
                  col1ShipId={col1ShipId}
                  col2ShipId={col2ShipId}
                  col2IslandFlag={col2Name === "Your Island"}
                  transferPath={transferPath}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
