export function ContactButtons() {
  return (
    <>
      <div className="sm:absolute top-6 right-6 flex items-center gap-3">
        <a
          href="https://discord.gg/X9wrNRqyq4"
          target="_blank"
          className="w-12 h-12 flex justify-center items-center bg-frame-normal bg-center bg-no-repeat bg-[length:100%_100%]"
        >
          <img
            className="w-1/2 ml-0.5"
            src="/frontend/onboarding/discord.png"
            alt="discord"
            width={32}
            height={32}
          />
        </a>

        <a
          href="https://x.com/InfiniseasDev"
          target="_blank"
          className="w-12 h-12 flex justify-center items-center bg-frame-normal bg-center bg-no-repeat bg-[length:100%_100%]"
        >
          <img
            className="w-1/2 ml-0.5"
            src="/frontend/onboarding/twitter.png"
            alt="twitter"
            width={32}
            height={32}
          />
        </a>
      </div>
    </>
  );
}
