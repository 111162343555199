import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import AppModal from "../../../app/ui/shared/AppModal";
import AppLoading from "../../../app/ui/shared/AppLoading";
import { HasValue, getComponentValueStrict } from "@latticexyz/recs";
import { useMUD } from "../../../useMUD";
import { useStore } from "../../../useStore";
import { useEntityQuery } from "@latticexyz/react";
import { decodeEntity } from "@latticexyz/store-sync/recs";
import { useNavigate } from "react-router-dom";
import { getLocalCoordinate } from "../../../utils/math";

export function CreatePlayerForm() {
  const [username, setUsername] = useState<string>("");
  const [pending, setPending] = useState<boolean>(false);
  const {
    networkLayer: {
      components: { Player },
    },
    phaserLayer: {
      scenes: {
        Main: { camera },
      },
    },
    writeWorldContract,
    externalWalletClient,
  } = useMUD();
  const navigate = useNavigate();

  const playerEntity = useEntityQuery([
    HasValue(Player, { owner: externalWalletClient?.account!.address }),
  ]);
  async function createAction() {
    if (!externalWalletClient?.account!.address)
      return toast.error("Please connect your wallet first!");
    if (!username) return toast.error("Please enter your player nickname!");
    setPending(true);

    // toast.loading("Creating a new player, it may take a while...");

    writeWorldContract("app__playerCreate", [username], () =>
      setPending(false)
    );
  }

  useEffect(() => {
    if (playerEntity.length > 0) {
      const {
        name,
        claimedIslandX,
        claimedIslandY,
        experience,
        level,
      }: {
        name: string;
        claimedIslandX: number;
        claimedIslandY: number;
        experience: number;
        level: number;
      } = getComponentValueStrict(Player, playerEntity[0]);
      const { id } = decodeEntity({ id: "uint8" }, playerEntity[0]);
      console.log({
        name,
        claimedIslandX,
        claimedIslandY,
        experience,
        level,
      });
      const { x, y } = getLocalCoordinate(claimedIslandX, claimedIslandY);
      console.log("island", { x, y });
      camera.centerOn(x, y);
      camera.phaserCamera.zoomTo(1, 2000, "Linear", true);
      //save necessary player info to store
      useStore.setState({
        player: {
          id,
          name,
          island: { x: claimedIslandX, y: claimedIslandY },
          experience,
          level,
        },
      });
      toast.success("Welcome aboard, Captain!", {
        id: "welcome-toast",
      });
    }
  }, [playerEntity]);

  useEffect(() => {
    if (!externalWalletClient) navigate("/");
  }, [externalWalletClient]);

  if (playerEntity.length > 0 || !externalWalletClient) return null;

  return (
    <AppModal frame="bg-frame-player">
      <div className="w-96 min-h-48 flex flex-col items-center text-2xl text-white py-8 gap-6">
        {!pending ? (
          <>
            <div className="flex flex-col items-center gap-1">
              <h1>Create Your Player Name</h1>
              <img
                src="/frontend/item/player-bar.png"
                alt="player-bar"
                width={300}
                height={10}
              />
            </div>

            <div className="flex items-center gap-1">
              <label htmlFor="newPlayerName">Name: </label>
              <input
                id="newPlayerName"
                className="w-32 bg-transparent border-b-[1px] border-white outline-none"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div
              className="flex items-center cursor-pointer gap-3"
              onClick={createAction}
            >
              <img
                src="/frontend/item/left-confirm.png"
                alt="left-confirm"
                width={20}
                height={10}
              />
              <span>Confirm</span>
              <img
                src="/frontend/item/right-confirm.png"
                alt="right-confirm"
                width={20}
                height={10}
              />
            </div>
          </>
        ) : (
          <AppLoading size={100} />
        )}
      </div>
    </AppModal>
  );
}
