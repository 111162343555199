import { useEffect, useState } from "react";
import { useQuery } from "urql";

import AppLoading from "../shared/AppLoading";
import { useMUD } from "../../../useMUD";

const EVENT_QUERY = `
  query Player($id: ID!) {
    player(id: $id) {
      id
      dailyQuest {
        completed
        id
        name
        quantity
        reward
        thresold
        time
      }
    }
  }`;

export function DailyQuestsMenu({
  handleCloseMenu,
}: {
  handleCloseMenu: () => void;
}) {
  const [dailyQuestList, setDailyQuestList] = useState<any[]>([]);
  const { player } = useMUD();

  const [result] = useQuery({
    query: EVENT_QUERY,
    variables: { id: player?.id },
  });
  const { data, fetching, error } = result;
  console.log({ data, fetching, error });

  function EnergyQuest(time: bigint) {
    const timeDiff = Date.now() - Number(time) * 1000;
    const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000;
    console.log({ timeDiff, TWENTY_FOUR_HOURS });
    if (timeDiff >= TWENTY_FOUR_HOURS) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (player && data) {
      const questList = data.player.dailyQuest.map((quest) => {
        if (quest.id.includes("Energy")) {
          return { ...quest, completed: EnergyQuest(quest.time) };
        }
        return quest;
      });
      setDailyQuestList(questList);
    }
  }, [player, data]);

  return (
    <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[666px] z-40 bg-paper-row bg-center bg-no-repeat bg-[length:100%_100%] text-white p-12 gap-6">
      <img
        className="absolute top-4 right-4 cursor-pointer"
        src="/frontend/icon/x-mark.png"
        alt="x-icon"
        width={36}
        height={36}
        onClick={handleCloseMenu}
      />

      <div className="w-full h-full bg-frame-prod bg-center bg-no-repeat bg-[length:100%_100%]">
        <h1 className="bg-frame-prod bg-center bg-no-repeat bg-[length:100%_100%] text-center text-3xl py-4">
          Daily Quests
        </h1>

        <div className="flex flex-col items-center min-h-72 overflow-y-auto px-8 pt-8 pb-11 gap-4">
          {!fetching ? (
            dailyQuestList &&
            dailyQuestList.map((quest) => (
              <div
                key={quest.id}
                className="w-full flex justify-between items-center"
              >
                <div className="flex items-center gap-2">
                  <div className="relative w-8 h-8">
                    {quest.completed && (
                      <img
                        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40"
                        src="/frontend/icon/checked.png"
                        alt="checked-icon"
                        width={32}
                        height={32}
                      />
                    )}
                    <img
                      className="w-7 h-7 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                      src="/frontend/icon/checkbox.png"
                      alt="checkbox-icon"
                      width={28}
                      height={28}
                    />
                  </div>

                  <p className="text-xl">{quest.name}</p>
                </div>

                <p className="text-xl">+{quest.reward} points</p>
              </div>
            ))
          ) : (
            <AppLoading size={100} />
          )}
        </div>
      </div>
    </div>
  );
}
