// Each roster has a specific order of ships, represented by an integer array called positions.
// This form can help players sort their ships in a given roster.


import toast from "react-hot-toast";
import { useMUD } from "../../../useMUD";

export default function SortRosterForm({
  rosterSequenceNumber,
  newRosterShipIds,
}: {
  rosterSequenceNumber: number;
  newRosterShipIds: bigint[];
}) {
  const {
    player,
    writeWorldContract,
  } = useMUD();

  // The execution logic of the contract function is to sequentially execute the
  // "ship position adjustment commands" composed of the parameters shipIds and positions.
  // To ensure the correctness of the adjustments, the simplest approach is to pass
  // all the ships' positions in the roster in order into the shipIds and positions arrays.
  const Positions = [...Array(newRosterShipIds.length)].map((_, i) => i);
  async function sortAction() {
    if(!player) return toast.error('Player not found');
    toast.loading("Sorting the roster, it may take a while...");
    writeWorldContract("app__rosterAdjustShipsPosition",[
      BigInt(player?.id),
      rosterSequenceNumber,
      Positions,
      newRosterShipIds
    ])
    
  }

  return (
    <button
      className="w-[144px] text-center text-white text-2xl cursor-pointer bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] py-2"
      type="button"
      onClick={sortAction}
    >
      Sort
    </button>
  );
}
