import {
  getComponentValueStrict,
  defineComponentSystem,
} from "@latticexyz/recs";
// import { decodeEntity } from "@latticexyz/store-sync/recs";
import { PhaserLayer } from "../createPhaserLayer";
import { TILE_WIDTH, TILE_HEIGHT, Animations } from "../constants";
import { getLocalCoordinate } from "../../../utils/math";
// import { useStore } from "../../../useStore";
import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
// import { mapResource } from "../../../utils/tools";
import { ROSTER_STATUS } from "../../../constant";
import { decodeEntity } from "@latticexyz/store-sync/recs";
import { Sprite } from "@latticexyz/phaserx/src/types";

export function createRosterSystem(layer: PhaserLayer) {
  const {
    world,
    scenes: {
      Main: { phaserScene },
    },
    networkLayer: {
      components: { Roster },
    },
  } = layer;

  defineComponentSystem(world, Roster, ({ entity }) => {
    const { status, updatedCoordinatesX, updatedCoordinatesY, baseExperience, environmentOwned, coordinatesUpdatedAt, targetCoordinatesX, targetCoordinatesY, originCoordinatesX, originCoordinatesY, sailDuration, setSailAt, currentSailSegment, shipBattleId, shipIds } =
      getComponentValueStrict(Roster, entity);
    const {playerId,sequenceNumber} = decodeEntity({"playerId": "uint256","sequenceNumber":"uint32"},entity);
    // console.log({ playerId,sequenceNumber, status, updatedCoordinatesX, updatedCoordinatesY, baseExperience, environmentOwned, coordinatesUpdatedAt, targetCoordinatesX, targetCoordinatesY, originCoordinatesX, originCoordinatesY, sailDuration, setSailAt, currentSailSegment, shipBattleId, shipIds });
    if(sequenceNumber !== 0){
      const localCoordinate = getLocalCoordinate(
        updatedCoordinatesX,
        updatedCoordinatesY
      );
      const sprite = phaserScene.children.getByName(playerId.toString()+sequenceNumber.toString()) as Phaser.GameObjects.Sprite;
      if(sprite){
        if(status !== ROSTER_STATUS.DESTROYED && shipIds.length > 0){
          sprite.play(Animations.RosterIdle);
          sprite.setDepth(4);
          sprite.setVisible(true);
        }else{
          // console.log({sprite});
          sprite.setVisible(false);
        }
      }else{
        const rosterSprite = phaserScene.add.sprite(localCoordinate.x, localCoordinate.y, "roster").setName(playerId.toString()+sequenceNumber.toString());
        if(status !== ROSTER_STATUS.DESTROYED && shipIds.length > 0){
          rosterSprite.play(Animations.RosterIdle);
          rosterSprite.setDepth(4);
          rosterSprite.setVisible(true);
        }
        else{
          // console.log({sprite});
          rosterSprite.setVisible(false);
        }
      }
    }
  });
}
