import { MUDChain } from "@latticexyz/common/chains";
import { Chain } from "viem/chains";

// If you are deploying to chains other than anvil or Lattice testnet, add them here
export const storyTestnet: Chain = {
    id: 1513,
    name: "Story Testnet",
    nativeCurrency: { name: "IP", symbol: "IP", decimals: 18 },
    rpcUrls: {
      default: {
        http: ["https://testnet.storyrpc.io "],
      },
      public: {
        http: ["https://testnet.storyrpc.io "],
      },
    },
    blockExplorers: {
      default: {
        name: "Story Testnet Explorer",
        url: "https://testnet.storyscan.xyz ",
      },
    },
    testnet: true,
  } as const satisfies Chain

  export const storyOdysseyTestnet: Chain = {
    id: 1516,
    name: "Story Odyssey Testnet",
    nativeCurrency: { name: "IP", symbol: "IP", decimals: 18 },
    rpcUrls: {
      default: {
        http: ["https://odyssey.storyrpc.io"],
      },
      public: {
        http: ["https://odyssey.storyrpc.io"],
      },
    },
    blockExplorers: {
      default: {
        name: "Story Odyssey Testnet Explorer",
        url: " https://odyssey-testnet-explorer.storyscan.xyz",
      },
    },
    testnet: true,
  } as const satisfies Chain

export const supportedChains: MUDChain[] = [storyTestnet as MUDChain, storyOdysseyTestnet as MUDChain];
