export enum Scenes {
  Main = "Main",
}

export enum Maps {
  Main = "Main",
}

export enum Animations {
  RosterIdle = "RosterIdle",
  RosterSail = "RosterSail",
}

export enum Sprites {
  ISLAND = 'island',
  ROSTER = 'roster',
}

export enum Assets {
  MainAtlas = "MainAtlas",
  Tileset = "Tileset",
}

export const TILE_HEIGHT = 64;
export const TILE_WIDTH = 64;
