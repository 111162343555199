import { PhaserLayer } from "../createPhaserLayer";
import { createCamera } from "./createCamera";
import { createMapSystem } from "./createMapSystem";
import { createIslandCoordinateSystem } from "./createIslandCoordinateSystem";
import { createRosterSystem } from "./createRosterSystem";

export const registerSystems = (layer: PhaserLayer) => {
  createCamera(layer);
  createMapSystem(layer);
  createIslandCoordinateSystem(layer);
  createRosterSystem(layer);
};
