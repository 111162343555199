import { useState, useEffect } from "react";

// import Leaderboard from "@/components/shared/Leaderboard";
import { EnergyFaucetForm } from "../form/EnergyFaucetForm";
import { PLAY_BUTTON_CLICK_SOUND } from "../../../utils/audio";
import { useReadContract } from "wagmi";
import { erc20Abi, formatUnits } from "viem";
import { useMUD } from "../../../useMUD";
import { useStore } from "../../../useStore";
import { useComponentValue } from "@latticexyz/react";
import { decodeEntity, singletonEntity } from "@latticexyz/store-sync/recs";
import Leaderboard from "./Leaderboard";
import { useAllowance } from "../../hooks/useAllowence";
import { useEntityQuery } from "@latticexyz/react";
import { HasValue } from "@latticexyz/recs";
// import { getComponentValueStrict } from "@latticexyz/recs";
import { getDirectionOfIsland, getLocalCoordinate } from "../../../utils/math";
import { useQuery } from "urql";

type Coordinate = {
  x: number;
  y: number;
};

type CoordinateDictionary = {
  [direction: string]: Coordinate[];
};

const EVENT_QUERY = `
  query Player($id: ID!) {
    totalCraftedShip(id: "TotalCraftedShip") {
      quantity
    }
  }`;

export function NavItems({
  getCombatHistoryClicked,
  getDailyQuestsClicked,
  getNewbieQuestsClicked,
  getIslandCardClicked,
  getShipsCardClicked,
}: {
  getCombatHistoryClicked: () => void;
  getDailyQuestsClicked: () => void;
  getNewbieQuestsClicked: () => void;
  getIslandCardClicked: () => void;
  getShipsCardClicked: () => void;
}) {
  const {
    networkLayer: {
      components: { EnergyToken, MapLocation },
    },
    phaserLayer: {
      scenes: {
        Main: {
          camera,
          phaserScene: { scale, sound },
        },
      },
    },
    externalWalletClient,
    player,
  } = useMUD();
  const token = useComponentValue(EnergyToken, singletonEntity);
  useAllowance(true);
  const { data: balance, refetch } = useReadContract({
    address: token?.tokenAddress as `0x${string}`,
    abi: erc20Abi,
    functionName: "balanceOf",
    args: [externalWalletClient?.account.address as `0x${string}`],
  });
  console.log({ balance });
  const energyBalance = balance ? Number(formatUnits(balance, 18)) : 0;
  const [isAudioPlaying, setIsAudioPlaying] = useState<boolean>(true);
  const [isFullscreenMode, setIsFullscreenMode] = useState<boolean>(false);
  const [leaderboardFlag, setLeaderBoardFlag] = useState<boolean>(false);
  const [claimIslandFlag, setClaimIslandFlag] = useState<boolean>(true);
  const [findIslandDropDown, setFindIslandDropDown] = useState<boolean>(false);

  const [expandedDirection, setExpandedDirection] = useState<string | null>(
    null
  );
  const [result] = useQuery({
    query: EVENT_QUERY,
    variables: { id: player?.id },
  });
  const { data, fetching, error } = result;
  console.log({ data, fetching, error });
  data
    ? data.totalCraftedShip
      ? console.log(data.totalCraftedShip.quantity)
      : console.log("no data in data", data.totalCraftedShip)
    : console.log("no data");

  const [
    UnclaimedIslandCoordinateDictionary,
    setUnclaimedIslandCoordinateDictionary,
  ] = useState<CoordinateDictionary>();
  const UnclaimedIslandEntity = useEntityQuery([
    HasValue(MapLocation, { occupiedBy: 0n }),
  ]);

  const fullScreen = () => {
    if (scale.isFullscreen) {
      scale.stopFullscreen();
      setIsFullscreenMode(false);
    } else {
      scale.startFullscreen();
      setIsFullscreenMode(true);
    }
  };

  useEffect(() => {
    console.log({ island: player?.island });
    if (!player?.island.x || !player?.island.y) return;
    console.log("@");
    setClaimIslandFlag(false);
  }, [player?.island]);

  useEffect(() => {
    useStore.setState({ energy: { balance: energyBalance, refetch } });
  }, [energyBalance]);

  // useEffect(() => {
  //   addEventListener("fullscreenchange", () => {
  //     console.log(document.fullscreenElement)
  //     if (document.fullscreenElement) setIsFullscreenMode(true)
  //     else setIsFullscreenMode(false)
  //   });
  //   return () => removeEventListener("fullscreenchange", () => {
  //     console.log(document.fullscreenElement)
  //     if (document.fullscreenElement) setIsFullscreenMode(true)
  //     else setIsFullscreenMode(false)
  //   })
  // }, [])
  useEffect(() => {
    if (UnclaimedIslandEntity.length === 0) return;
    findUnclaimedIsland();
    console.log("#");
  }, [UnclaimedIslandEntity]);

  function addCoordinate(direction: string, x: number, y: number) {
    setUnclaimedIslandCoordinateDictionary((prevState) => {
      // Create a new copy of the state to avoid mutating the previous state
      const newState = { ...prevState };

      // Check if the direction already exists as a key
      if (!newState[direction]) {
        newState[direction] = []; // Create an array for new directions
      }

      // Push the new coordinate to the appropriate direction array
      newState[direction].push({ x, y });

      return newState; // Return the updated state
    });
  }

  function findUnclaimedIsland() {
    UnclaimedIslandEntity.map((entity) => {
      const { x, y } = decodeEntity({ x: "uint32", y: "uint32" }, entity);
      const localCordinate = getLocalCoordinate(x, y);
      const Direction = getDirectionOfIsland(
        localCordinate.x,
        localCordinate.y
      );
      addCoordinate(Direction, localCordinate.x, localCordinate.y);
    });
  }

  //for handeling the the clicked direction
  const handleDirectionClick = (direction: string) => {
    setExpandedDirection((prev) => (prev === direction ? null : direction));
  };

  function RandomIsland() {
    const allCoordinates: Coordinate[] = [];
    // Gather all coordinates from the dictionary
    for (const coordinates of Object.values(
      UnclaimedIslandCoordinateDictionary
    )) {
      allCoordinates.push(...coordinates); // Flatten the arrays of coordinates
    }

    // Check if there are any coordinates to select from
    if (allCoordinates.length === 0) {
      console.log("No coordinates available to select");
      return;
    }

    // Select a random coordinate
    const randomIndex = Math.floor(Math.random() * allCoordinates.length);
    const selectedCoordinate = allCoordinates[randomIndex];

    console.log("Selected Coordinate:", selectedCoordinate); // Log the selected coordinate
    handleCameraToIsland(selectedCoordinate);
  }
  function handleCameraToIsland(coordinate: Coordinate) {
    camera.centerOn(coordinate.x, coordinate.y);
    camera.phaserCamera.zoomTo(1, 2000, "Sine.easeInOut", true);
    setFindIslandDropDown(false);
  }

  return (
    <>
      {/* For claimimg island */}
      {claimIslandFlag && (
        <div className="fixed w-40 top-28 left-1/2 transform -translate-x-1/2 flex flex-col justify-center gap-3 z-40 font-bold items-center text-lg">
          <div
            onClick={() => {
              setFindIslandDropDown((prev) => !prev);
            }}
            className="w-28 h-8 flex justify-center items-center bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] text-white"
          >
            Find Island
          </div>
          {findIslandDropDown && (
            <div className="flex w-full h-36 items-center flex-col bg-paper-row bg-center bg-no-repeat bg-[length:100%_100%] text-black overflow-y-auto">
              <ul>
                <li className="flex justify-center" onClick={RandomIsland}>
                  Random
                </li>
                {UnclaimedIslandCoordinateDictionary &&
                  Object.entries(UnclaimedIslandCoordinateDictionary).map(
                    ([direction, coordinates]) => (
                      <li
                        className="flex justify-center flex-col"
                        key={direction}
                      >
                        <button onClick={() => handleDirectionClick(direction)}>
                          {direction}
                        </button>

                        {expandedDirection === direction && (
                          <ul className="flex justify-center flex-col items-center">
                            {coordinates.map((coordinate, index) => (
                              <>
                                <img
                                  src="/frontend/onboarding/stick.png"
                                  alt="stick"
                                />
                                <li
                                  key={index}
                                  onClick={() => {
                                    handleCameraToIsland(coordinate);
                                  }}
                                >
                                  X: {coordinate.x}, Y: {coordinate.y}
                                </li>
                              </>
                            ))}
                          </ul>
                        )}
                      </li>
                    )
                  )}
              </ul>
            </div>
          )}
        </div>
      )}

      {/* Top-left buttons */}
      <div className="fixed top-6 left-6 flex flex-col gap-3 z-40">
        {/* <img
          className="cursor-pointer"
          src="/frontend/home/settings.png"
          alt="setting-button"
          width={50}
          height={50}
        /> */}
        <img
          className="cursor-pointer"
          src="/frontend/home/points.png"
          alt="points-button"
          width={50}
          height={50}
          onClick={() => {
            PLAY_BUTTON_CLICK_SOUND();
            setLeaderBoardFlag(true);
          }}
        />
        {/* <img
          className="cursor-pointer"
          src="/frontend/home/history.png"
          alt="history-button"
          width={50}
          height={50}
          onClick={() => {
            PLAY_BUTTON_CLICK_SOUND();
            getCombatHistoryClicked();
          }}
        /> */}
        <img
          className="cursor-pointer"
          src="/frontend/home/daily-quest.png"
          alt="daily-quest-button"
          width={50}
          height={50}
          onClick={() => {
            PLAY_BUTTON_CLICK_SOUND();
            getDailyQuestsClicked();
          }}
        />
        <img
          className="cursor-pointer"
          src="/frontend/home/newbie-quest.png"
          alt="newbie-quest-button"
          width={50}
          height={50}
          onClick={() => {
            PLAY_BUTTON_CLICK_SOUND();
            getNewbieQuestsClicked();
          }}
        />
      </div>

      {/* Top-right items */}
      <div className="fixed top-4 right-4 flex flex-col items-center gap-3 z-40">
        {/* Energy bar */}
        <div className="relative">
          <img
            src="/frontend/home/energy-bar.png"
            alt="energy-bar"
            width={240}
            height={120}
          />
          <p className="absolute left-[95px] w-[52px] bottom-[23px] text-white text-center text-lg">
            {energyBalance.toFixed(2)}
          </p>
        </div>
        {/* Faucet */}
        <EnergyFaucetForm />

        {/* Leaderboard */}
        {leaderboardFlag && (
          <Leaderboard handleCloseMenu={() => setLeaderBoardFlag(false)} />
        )}
      </div>

      {/* Bottom-left buttons */}
      <div className="fixed bottom-6 left-6 flex flex-col items-center gap-3 z-40">
        <img
          className="cursor-pointer"
          id="zoom-in"
          src={"/frontend/button/zoom-in.png"}
          alt="zoom-in"
          width={40}
          height={40}
          onClick={() =>
            camera.phaserCamera.zoomTo(
              Math.min(camera.phaserCamera.zoom + 0.1, 2),
              1000,
              "Linear",
              true
            )
          }
        />
        <img
          className="cursor-pointer"
          id="zoom-out"
          src={"/frontend/button/zoom-out.png"}
          alt="zoom-out"
          width={40}
          height={40}
          onClick={() =>
            camera.phaserCamera.zoomTo(
              Math.max(camera.phaserCamera.zoom - 0.1, 0.5),
              1000,
              "Linear",
              true
            )
          }
        />
        <img
          className="cursor-pointer"
          id="audio-play"
          src={
            isAudioPlaying
              ? "/frontend/button/play.png"
              : "/frontend/button/mute.png"
          }
          alt="points-button"
          width={50}
          height={50}
          onClick={() => null}
        />
        <img
          className="cursor-pointer"
          id="fullscreen-max"
          src={
            isFullscreenMode
              ? "/frontend/button/exit-fullscreen.png"
              : "/frontend/button/go-fullscreen.png"
          }
          alt="points-button"
          width={50}
          height={50}
          onClick={fullScreen}
        />
      </div>

      {/* Bottom-right cards */}
      <div className="flex items-center fixed bottom-2 right-6 gap-1 z-40">
        <img
          className="cursor-pointer"
          src="/frontend/home/island-card.png"
          alt="island-card"
          width={110}
          height={160}
          onClick={() => {
            PLAY_BUTTON_CLICK_SOUND();
            getIslandCardClicked();
          }}
        />
        <img
          className="cursor-pointer"
          src="/frontend/home/ships-card.png"
          alt="ship-card"
          width={110}
          height={160}
          onClick={() => {
            PLAY_BUTTON_CLICK_SOUND();
            getShipsCardClicked();
          }}
        />
      </div>
      {/* ShipEnergyBar */}
      <div className="fixed bottom-6 left-1/2 transform -translate-x-1/2 flex flex-col">
        <div className="relative">
          <img
            src="/frontend/home/ShipEnergyBar.png"
            alt="Ship-Bar"
            width={350}
            height={45}
          />
          <div className="flex items-center gap-3 justify-center">
            <p className="text-yellow-400 font-bold text-2xl">
              {data
                ? data.totalCraftedShip
                  ? data.totalCraftedShip.quantity
                  : 0
                : 0}
              /10000
            </p>
            <img
              src="/frontend/home/Boat.png"
              alt="Boat"
              width={56}
              height={40}
            />
          </div>
        </div>
      </div>
    </>
  );
}
