import { useState } from "react";
import toast from "react-hot-toast";

import AppModal from "../shared/AppModal";
import AppLoading from "../shared/AppLoading";

import { PLAY_CRAFTING_SOUND } from "../../../utils/audio";
import { useMUD } from "../../../useMUD";
import { ValueOf } from "../../../utils/tools";
import { SkillType } from "../../../constant";
import { useAllowance } from "../../hooks/useAllowence";

export default function StartCraftForm({
  shipCopper,
  shipLog,
  shipCotton,
  inventoryCopper,
  inventoryLog,
  inventoryCotton,
  handleCloseModal,
  activeProducts,
}: {
  shipCopper: number;
  shipLog: number;
  shipCotton: number;
  inventoryCopper: number;
  inventoryLog: number;
  inventoryCotton: number;
  handleCloseModal: () => void;
  activeProducts: {skillType: ValueOf<typeof SkillType>, skillSequence: number}[];
}) {
  const [pending, setPending] = useState<boolean>(false);
  const {
    player,
    writeWorldContract,
    energy: {balance, refetch},
  } = useMUD();
  const {allowence, writeAllowance} = useAllowance();

  function startCraftAction() {
    if (!player) return toast.error("Please login first!");

      if (inventoryCopper < 0)
        return toast.error("Not enough copper in your inventory!");
      if (inventoryLog < 0)
        return toast.error("Not enough log in your inventory!");
      if (inventoryCotton < 0)
        return toast.error("Not enough cotton in your inventory!");
      if (shipCopper + shipLog + shipCotton !== 15)
        return toast.error("The total resources added must be 15!");

      if (activeProducts.some(({skillType}) => skillType === SkillType.CRAFTING))
        return toast.error(
          "Please wait for the end of the crafting process and harvest first!"
        );

      if (balance < 5) return toast.error("You don't have enough energy coin, please claim some first!");
      if (Math.floor(allowence) < 5){
        writeAllowance();
        toast.error("You need to approve the energy token first!");
        handleCloseModal();
        return
      }
      setPending(true);
      writeWorldContract("app__uniApiStartShipProduction", [
        SkillType.CRAFTING,
        BigInt(player.id),
        0,
        1000000001,
        [
          {
            itemId: 102,
            quantity: shipCotton,
          },
          {
            itemId: 200,
            quantity: shipLog,
          },
          {
            itemId: 301,
            quantity: shipCopper,
          },
        ],
      ], ({status}) => {
        if (status) {
          PLAY_CRAFTING_SOUND();
          handleCloseModal();
        }
        setPending(false);
      }
      );
      refetch();
  }


  return (
    <AppModal frame="bg-frame-prod">
      <div className="w-96 min-h-48 flex flex-col justify-between items-center text-2xl text-white">
        {!pending ? (
          <>
            <div className="flex flex-col items-center pt-8 pb-5 gap-2">
              <p>costs 5 energy token</p>
              <p>15 seconds time duration</p>
            </div>

            <div className="flex w-full items-center">
              <div
                className="flex-1 bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] text-center cursor-pointer py-3"
                onClick={startCraftAction}
              >
                Create
              </div>

              <div
                className="flex-1 bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] text-center cursor-pointer py-3"
                onClick={() => handleCloseModal()}
              >
                Cancel
              </div>
            </div>
          </>
        ) : (
          <AppLoading size={100} />
        )}
      </div>
    </AppModal>
  );
}
