import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMUD } from "../../../useMUD";

import { calculateTimeLeftInSec, formatTimeFromSec } from "../../../utils/tools";
import { PLAY_BUTTON_CLICK_SOUND, PLAY_CLAIM_ENERGY_SOUND } from "../../../utils/audio";
import { useEntityQuery } from "@latticexyz/react";
import { Has, HasValue, getComponentValueStrict } from "@latticexyz/recs";
import { decodeEntity } from "@latticexyz/store-sync/recs";

export function EnergyFaucetForm() {
  const {
    networkLayer: {
      components: { MapLocation, EnergyDrop }
    },
    player,
    writeWorldContract,
    externalWalletClient,
    energy:{refetch},
  } = useMUD();
  const [timeLeftInSec, setTimeLeftInSec] = useState<number>(-1);
  
  const claimedIslandEntity = useEntityQuery([
    HasValue(MapLocation, { occupiedBy: player ? BigInt(player?.id) : 0n }),
  ]);
  const playerIsland = claimedIslandEntity.length ? getComponentValueStrict(MapLocation, claimedIslandEntity[0]) : null;

  const energyDropEntities = useEntityQuery([Has(EnergyDrop)]);
  
  async function claimAction() {
    if (!externalWalletClient || !player) return toast.error("Please login first!");
    // if (timeLeftInSec > 0) return toast.error(`You can only claim this after ${formatTimeFromSec(timeLeftInSec)}.`);

    // toast.loading("Requesting testnet energy coins, it may take a while...");
    writeWorldContract("app__energyDropRequest", [externalWalletClient.account?.address], ({status}) => {
      if(status) {
        PLAY_CLAIM_ENERGY_SOUND();
        refetch();
      }
    });
  }

  useEffect(() => {
    if (!externalWalletClient) return;
    const PlayerLastDropAt = energyDropEntities.map((entity)=>{
      const {accountAddress} = decodeEntity({"accountAddress":"address"},entity);
      if(accountAddress.toString() === externalWalletClient.account?.address.toString()){
        const {lastDroppedAt} = getComponentValueStrict(EnergyDrop,entity);
        return lastDroppedAt;
      }
    }).filter(data=> data);
    console.log({PlayerLastDropAt});
    const grantedAt = (Number(PlayerLastDropAt) * 1000).toString()
    const timeInSec = calculateTimeLeftInSec(grantedAt, (60 * 60 * 24).toString());
    setTimeLeftInSec(timeInSec);
    const timer = setInterval(() => {
      setTimeLeftInSec((prev) => {
        if (prev <= 0) return 0;
        return prev - 1;
      });
    }, 1000);
  
    return () => clearInterval(timer);
  }, [energyDropEntities]);

  return (
        <button
        disabled={timeLeftInSec > 0 || !player || (player && (!player.island.x && !player.island.y))}
          className="text-white flex flex-col items-center cursor-pointer z-40 disabled:cursor-not-allowed disabled:text-gray-400"
          onClick={() => {
            PLAY_BUTTON_CLICK_SOUND();
            claimAction();
          }}
        >
          <div className="bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] flex items-center text-lg px-5 py-2 gap-2 z-10">
            <img src="/frontend/icon/energy.png" alt="energy-icon" width={20} height={40} />
            <span>Claim Energy</span>
          </div>

          <div className="w-36 bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] flex justify-center items-center text-sm py-1.5 -mt-1.5 gap-2 z-0">
            {timeLeftInSec === 0 ? "Available now" : `Available in ${formatTimeFromSec(timeLeftInSec)}`}
          </div>
        </button>
  );
}
