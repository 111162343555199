import { CustomConnectButton } from "./ui/shared/CustomConnectButton";
import { ContactButtons } from "./ui/shared/ContactButtons";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        background: "url('frontend/onboarding/cover.png')",
        backgroundSize: "cover",
      }}
      className="flex flex-col justify-center items-center space-y-6"
    >
      {/* <CustomConnectButton /> */}

      <img
        className="w-2/3 z-10"
        src="/frontend/onboarding/title.png"
        alt="title-img"
        width={1000}
        height={200}
      />

      <div
        className="w-72 h-20 mt-6 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex flex-col justify-center items-center cursor-pointer"
      >
        <span className="text-white text-3xl leading-none mt-1">Coming Soon</span>
      </div>
      <div
        onClick={() => navigate("/quest")}
        className="w-72 h-20 mt-6 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex flex-col justify-center items-center cursor-pointer"
      >
        <span className="text-white text-3xl leading-none mt-1">Odyssey Quest</span>
      </div>
      <ContactButtons />
    </div>
  );
};
