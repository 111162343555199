
export default function AppModal({ frame, children }: { frame?: string; children: React.ReactNode }) {
  return (
    <div
      className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-between items-center ${frame} bg-center bg-no-repeat bg-[length:100%_100%] z-40 gap-2`}
    >
      <div>
        {children}
      </div>
    </div>
  );
}
