import { createBrowserRouter } from "react-router-dom";
import { Game } from "./app/Game";
import { Home } from "./app/Home";
import Faucet from "./app/Faucet";
import { QuestsMenu } from "./app/ui/menus/QuestsMenu";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/game",
    element: <Game />,
  },
  {
    path: "/faucet",
    element: <Faucet />,
  },
  {
    path: "/quest",
    element: <QuestsMenu />,
  }
]);
