import { useState } from "react";

import StartProductForm from "../form/StartProductForm";
import { ValueOf } from "../../../utils/tools";
import { SkillType } from "../../../constant";

const productImgs = {
  ore: "/frontend/resource/copper.png",
  wood: "/frontend/resource/tree.png",
  seed: "/frontend/resource/cotton.png",
} as any;

const unlockedProductImgs = {
  ore: "/frontend/product/copper-locked.png",
  wood: "/frontend/product/tree-locked.png",
  seed: "/frontend/product/cotton-locked.png",
} as any;

export default function IslandProductMenu({
  productType,
  oreLeft,
  woodLeft,
  seedsLeft,
  activeProducts,
}: {
  productType: string;
  oreLeft: number;
  woodLeft: number;
  seedsLeft: number;
  activeProducts: {
    skillType: ValueOf<typeof SkillType>;
    skillSequence: number;
  }[];
}) {
  const [modalFlag, setModalFlag] = useState<boolean>(false);

  return (
    <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3/5 h-3/5 z-40 grid grid-cols-4 gap-4 max-xl:h-[50%] max-xl:w-[50%]">
      <div
        className="flex flex-col justify-center items-center bg-center bg-no-repeat bg-frame-lg bg-[length:100%_100%] text-white cursor-pointer gap-3"
        onClick={() => setModalFlag(true)}
      >
        {productType === "ore" && (
          <>
            <p className="text-lg">Mine Copper Ore</p>
            <div className="flex items-center gap-2">
              <p>10 XP /</p>
              <img
                src="/frontend/icon/clock.png"
                alt="clock-icon"
                width={20}
                height={20}
              />
              <p>3 Seconds</p>
            </div>
          </>
        )}
        {productType === "wood" && (
          <>
            <p className="text-lg">Cut Normal Wood</p>
            <div className="flex items-center gap-2">
              <p>10 XP /</p>
              <img
                src="/frontend/icon/clock.png"
                alt="clock-icon"
                width={20}
                height={20}
              />
              <p>3 Seconds</p>
            </div>
          </>
        )}
        {productType === "seed" && (
          <>
            <p className="text-lg">Plant Cotton Seeds</p>
            <div className="flex items-center gap-2">
              <p>10 XP /</p>
              <img
                src="/frontend/icon/clock.png"
                alt="clock-icon"
                width={20}
                height={20}
              />
              <p>15 Seconds</p>
            </div>
          </>
        )}

        <img
          src={productImgs[productType]}
          alt="product-icon"
          width={75}
          height={75}
        />

        <div className="text-center">
          <p>0 / 500</p>

          <div className="flex justify-between items-center gap-2">
            <img
              src="/frontend/icon/trophy.png"
              alt="trophy-icon"
              width={20}
              height={20}
            />
            <img
              src="/frontend/product/progress-bg.png"
              alt="progress-bg"
              width={150}
              height={10}
            />
          </div>
        </div>
      </div>

      {[10, 20, 30, 40, 50, 60, 70].map((id) => (
        <span
          key={id}
          className="flex flex-col justify-center items-center bg-center bg-no-repeat bg-frame-lg bg-[length:100%_100%] text-white cursor-pointer gap-6"
        >
          <div className="flex items-center gap-2">
            <img
              src="/frontend/icon/lock.png"
              alt="lock-icon"
              width={30}
              height={30}
            />
            <p className="text-2xl">Locked</p>
          </div>

          <img
            src={unlockedProductImgs[productType]}
            alt="product-icon"
            width={75}
            height={75}
          />

          <p className="text-2xl">Level {id}</p>
        </span>
      ))}

      {modalFlag && (
        <StartProductForm
          productType={productType}
          oreLeft={oreLeft}
          woodLeft={woodLeft}
          seedsLeft={seedsLeft}
          handleCloseModal={() => setModalFlag(false)}
          activeProducts={activeProducts}
        />
      )}
    </div>
  );
}
