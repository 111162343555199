import { PhaserLayer } from "../createPhaserLayer";

export const createCamera = (layer: PhaserLayer) => {
  const {
    scenes: {
      Main: {
        camera: { phaserCamera },
      },
    },
  } = layer;
  phaserCamera.setBounds(-10000, -10000, 20000, 20000, true);
  phaserCamera.centerOn(0, 0).zoomTo(0.5, 2000, "Linear", true);
};
