import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  calculateTimeLeftInSec,
  formatTimeFromSec,
} from "../../../utils/tools";
import { PLAY_CLAIM_ENERGY_SOUND } from "../../../utils/audio";
import { useStore } from "../../../useStore";
import { useQuery } from "urql";

const EVENT_QUERY = `
  query Player($id: ID!) {
    energyDrop(id: $id) {
      id
      creationTime
    }
  }`;

export default function EnergyOnboardingForm() {
  const { writeWorldContract, externalWalletClient } = useStore(
    (state) => state
  );
  const [startedAt, setStartedAt] = useState<string>("");
  const [timeLeftInSec, setTimeLeftInSec] = useState<number>(-1);
  const [pending, setPending] = useState<boolean>(false);
  const currentAccountAddress = externalWalletClient?.account.address;
  const lowercurrentAddress = currentAccountAddress.toLowerCase();
  console.log({ currentAccountAddress });
  console.log({ lowercurrentAddress });
  const [result, refetchQuery] = useQuery({
    query: EVENT_QUERY,
    variables: { id: lowercurrentAddress },
    pause: !lowercurrentAddress, // Pause the query if the address is undefined
  });
  const { data, fetching, error } = result;
  console.log({ data, fetching, error });

  async function initTimeLeft(grantedAt: string) {
    setPending(true);
    const timeInSec = calculateTimeLeftInSec(
      grantedAt,
      (60 * 60 * 24).toString()
    );

    setStartedAt(grantedAt);
    setTimeLeftInSec(timeInSec);

    setPending(false);
  }

  async function faucetAction() {
    if (!currentAccountAddress)
      return toast.error("Please connect your wallet first!");
    if (timeLeftInSec > 0)
      return toast.error(
        `You can only claim this after ${formatTimeFromSec(timeLeftInSec)}.`
      );

    toast.loading("Requesting testnet energy coins, it may take a while...");
    writeWorldContract(
      "app__energyDropRequest",
      [currentAccountAddress],
      ({ status }) => {
        if (status) {
          PLAY_CLAIM_ENERGY_SOUND();
        }
      }
    );
  }

  useEffect(() => {
    if (!currentAccountAddress || !data) return;
    refetchQuery();
    if (!data.energyDrop) return;
    const PlayerLastDropAt = data.energyDrop.creationTime;
    const grantedAt = (Number(PlayerLastDropAt) * 1000).toString();
    initTimeLeft(grantedAt);
  }, [currentAccountAddress, data]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeftInSec((prev) => {
        if (prev <= 0) return 0;
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeftInSec, startedAt]);

  return (
    <>
      <button
        className="w-64 h-16 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex justify-center items-center gap-3"
        type="button"
        disabled={pending}
        onClick={faucetAction}
      >
        <img
          src="/frontend/onboarding/claim-energy.png"
          alt="claim"
          width={42}
          height={42}
        />
        <span className="text-xl leading-none mt-1">GET ENERGY</span>
      </button>

      <div className="text-center -mt-2">
        {currentAccountAddress && !pending ? (
          <p className="flex justify-center items-center gap-2">
            <span>
              {timeLeftInSec === 0 ? "" : `${formatTimeFromSec(timeLeftInSec)}`}
            </span>
            <span className="text-[#FFB800]">
              {timeLeftInSec === 0
                ? "Available now"
                : "until next energy claim"}
            </span>
          </p>
        ) : (
          <p>— —</p>
        )}
        <img
          className="w-full"
          src="/frontend/onboarding/stick.png"
          alt="stick"
          width={100}
          height={10}
        />
      </div>
    </>
  );
}
