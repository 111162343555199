import { Toaster } from "react-hot-toast";

export function AppToaster() {
  return (
    <Toaster
      toastOptions={{
        className: "text-sm",
        duration: 5000,
        style: {
          maxWidth: "300px",
          padding: "12px 24px",
          backgroundImage: "url('/frontend/frame/toast-frame.png')",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        },
      }}
      position="bottom-left"
      containerStyle={{ bottom: 30, left: 40 }}
    />
  );
}
