import { useEffect, useMemo, useState } from "react";
import { getComponentValue } from "@latticexyz/recs";
import { concat, map } from "rxjs";
import { useObservableValue } from "../../hooks/useObservableValue";
import { filterNullish } from "@latticexyz/utils";
import {
  DISCORD_URL,
  HOW_TO_PLAY_URL,
  LATTICE_URL,
  MUD_URL,
} from "../../links";
import { SyncStep } from "@latticexyz/store-sync";
import { singletonEntity } from "@latticexyz/store-sync/recs";
import { useMUD } from "../../../useMUD";

type Props = {
  usePrepTime?: boolean;
};
export const LoadingScreen = ({ usePrepTime }: Props) => {
  const { networkLayer } = useMUD();
  const [prepareGameProgress, setPrepareGameProgress] = useState(0);
  const [startGameProgress, setStartGameProgress] = useState(false);

  const loadingState = useObservableValue(
    useMemo(() => {
      if (!networkLayer) return;
      const {
        components: { SyncProgress },
      } = networkLayer;

      // use LoadingState.update$ as a trigger rather than a value
      // and concat with an initial value to trigger the first look up
      return concat([1], SyncProgress.update$).pipe(
        map(() => {
          const loadingState = getComponentValue(SyncProgress, singletonEntity);
          return loadingState ?? null;
        }),
        filterNullish()
      );
    }, [networkLayer]),
    {
      message: "Connecting",
      percentage: 0,
      step: SyncStep.INITIALIZE,
      lastBlockNumberProcessed: 0n,
      latestBlockNumber: 0n,
    }
  );

  useEffect(() => {
    if (!usePrepTime) return;
    if (!networkLayer) return;
    if (loadingState.step !== SyncStep.LIVE) return;

    setStartGameProgress(true);
  }, [loadingState, networkLayer, prepareGameProgress, usePrepTime]);

  const prepTimeSeconds = import.meta.env.PROD ? 10 : 1;
  useEffect(() => {
    if (!startGameProgress) return;

    const interval = setInterval(
      () => {
        setPrepareGameProgress((prev) => {
          if (prev === 100) {
            clearInterval(interval);
            return prev;
          }
          return prev + 1;
        });
      },
      (prepTimeSeconds * 1000) / 100
    );

    return () => clearInterval(interval);
  }, [networkLayer, prepTimeSeconds, startGameProgress, usePrepTime]);

  const doneLoading = usePrepTime
    ? prepareGameProgress === 100
    : loadingState.step === SyncStep.LIVE;

  const showPrepMessage = loadingState.step === SyncStep.LIVE && usePrepTime;

  const loadingMessage = showPrepMessage
    ? "Preparing Game"
    : loadingState.message;
  const loadingPercentage = showPrepMessage
    ? prepareGameProgress
    : Math.round(loadingState.percentage);
  const showPercentage = showPrepMessage || loadingPercentage > 0;

  if (doneLoading) return null;
  return (
    <div
      style={{
        zIndex: 1200,
        background:
          "linear-gradient(rgba(24, 23, 16, 0.4), rgba(24, 23, 16, 0.4)), url(frontend/onboarding/cover.png)",
        backgroundPosition: "right",
        backgroundSize: "cover",
      }}
      className="fixed text-white items-center justify-center w-screen h-screen bg-black p-12 flex flex-col"
    >
      <div className="w-[540px] h-[240px] flex flex-col gap-5 p-8 justify-items bg-frame-login text-lg bg-center bg-no-repeat bg-[length:100%_100%]">
        {!doneLoading && (
          <div className="flex flex-col grow items-center text-center">
            <div>
              Infinite Seas is a strategic, onchain MMO game running on Story
              Blockchain.
            </div>
          </div>
        )}
        {!doneLoading && (
          <div className="flex flex-row w-full justify-center items-center">
            {/* <img height="64px" width="64px" src="/public/assets/dragoon_attack.gif" /> */}
            <div className="w-4"></div>
            <div className="text-center text-3xl text-ss-text-default">
              {loadingMessage}
              {showPercentage && (
                <div className="text-ss-blue">({loadingPercentage}%)</div>
              )}
            </div>
            <div className="w-4"></div>
            {/* <img height="64px" width="64px" src="/public/assets/dragoon_attack.gif" /> */}
          </div>
        )}
      </div>

      <div className="flex justify-between stretch">
        <div className="absolute bottom-6 left-6 flex gap-5 justify-between">
          <a
            href="https://discord.gg/X9wrNRqyq4"
            target="_blank"
            className="cursor-pointer w-12 h-12 flex justify-center items-center bg-frame-normal bg-center bg-no-repeat bg-[length:100%_100%]"
          >
            <img
              className="w-1/2 ml-0.5"
              src="/frontend/onboarding/discord.png"
              alt="discord"
              width={32}
              height={32}
            />
          </a>

          <a
            href="https://x.com/InfiniseasDev"
            target="_blank"
            className="cursor-pointer w-12 h-12 flex justify-center items-center bg-frame-normal bg-center bg-no-repeat bg-[length:100%_100%]"
          >
            <img
              className="w-1/2 ml-0.5"
              src="/frontend/onboarding/twitter.png"
              alt="twitter"
              width={32}
              height={32}
            />
          </a>
        </div>

        <span className="absolute bottom-6 right-6 ml-4 text-neutral-300">
          powered by{" "}
          <a className="text-ss-gold" href={MUD_URL}>
            MUD
          </a>
        </span>
      </div>
    </div>
  );
};
