// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { createPublicClient, fallback, http, createWalletClient, Hex, getContract, ClientConfig } from "viem";
import { encodeEntity, syncToRecs } from "@latticexyz/store-sync/recs";
import { map, filter } from "rxjs";
import { getNetworkConfig } from "./getNetworkConfig";
import { world } from "./world";
import IWorldAbi from "../IWorld.abi.json";
import { createBurnerAccount, getNonceManager, transportObserver } from "@latticexyz/common";
import mudConfig from "../mud.config";
import { createClock } from "./createClock";
import { createWaitForTransaction } from "./waitForTransaction";
import { transactionQueue } from "@latticexyz/common/actions";

export type SetupNetworkResult = Awaited<ReturnType<typeof setupNetwork>>;

export async function setupNetwork() {
  const networkConfig = await getNetworkConfig();

  const clientOptions = {
    chain: networkConfig.chain,
    transport: transportObserver(fallback([http()])),
    pollingInterval: 250,
  } as const satisfies ClientConfig;

  const publicClient = createPublicClient(clientOptions);

  const txReceiptClient = createPublicClient({
    ...clientOptions,
    transport: transportObserver(fallback([http()], { retryCount: 0 })),
    pollingInterval: 250,
  });

  const { 
    components,
    latestBlock$,
    storedBlockLogs$,
    // waitForTransaction
   } = await syncToRecs({
    world,
    config: mudConfig,
    address: networkConfig.worldAddress as Hex,
    publicClient: txReceiptClient,
    startBlock: BigInt(networkConfig.initialBlockNumber),
    // making the block range very small here, if we've had problems with large worlds overloading the RPC
    // maxBlockRange: 100n,
  });
 
  const clock = createClock(networkConfig.clock);
  world.registerDisposer(() => clock.dispose());

  const waitForTransaction = createWaitForTransaction({
    storedBlockLogs$,
    client: txReceiptClient,
  });


  // time it takes to receive events from a sent tx
  let meanResponseTime = 2000;
  const updateMeanResponseTime = (newResponseTime: number) => {
    meanResponseTime = newResponseTime;
  };
  const getMeanResponseTime = () => meanResponseTime;

  latestBlock$
    .pipe(
      map((block) => {
        // accelerate the player's clock to compensate for the time it
        // takes for their tx to be included in a block. this has the
        // effect of making the beginnings of turns feel more responsive
        // as you can "pre-send" your txs to arrive at the start of the
        // next turn. this will hopefully alleviate the latency advantage
        // at the beginning of turns.
        // unfortunately, we can only change the player's clock by whole seconds
        // because that is the granularity of the block timestamps.
        // in my manual testing 2 second acceleration was possible with a response time
        // around 3600 ms.
        const clientTimeAhead = Math.ceil(getMeanResponseTime() / 2 / 1000) * 1000;
        return Number(block.timestamp) * 1000 + clientTimeAhead;
      }), // Map to timestamp in ms
      filter((blockTimestamp) => blockTimestamp !== clock.lastUpdateTime), // Ignore if the clock was already refreshed with this block
      filter((blockTimestamp) => blockTimestamp !== clock.currentTime), // Ignore if the current local timestamp is correct
    )
    .subscribe(clock.update); // Update the local clock


  const burnerAccount = createBurnerAccount(networkConfig.privateKey as Hex);
  const walletClient = createWalletClient({
    ...clientOptions,
    account: burnerAccount,
  });

  const txQueue = transactionQueue({
    queueConcurrency: 3,
  });
  const customWalletClient = walletClient.extend(txQueue);
  const walletNonceManager = await getNonceManager({
    client: publicClient,
    address: walletClient.account.address,
    blockTag: "pending",
    queueConcurrency: 3,
  });
  walletNonceManager.resetNonce();

  // const write$ = new Subject<ContractWrite>();
  const worldContract = getContract({
    address: networkConfig.worldAddress as Hex,
    abi: IWorldAbi,
    client: { public: publicClient, wallet: customWalletClient },
    // onWrite: (write) => write$.next(write),
  });

  return {
    world,
    components,
    playerEntity: encodeEntity({ address: "address" }, { address: walletClient.account!.address }),
    publicClient,
    txReceiptClient,
    walletClient,
    walletNonceManager,
    waitForTransaction,
    worldContract,
    clock,
    latestBlock$,
    storedBlockLogs$,
    responseTime: {
      updateMeanResponseTime,
      getMeanResponseTime,
    },
  };
}
