import { ReactNode } from "react"

export function TxToast({
  title,
  logo = null,
  description,
  status,
}: {
  title: string | ReactNode
  logo?: ReactNode
  description?: string | ReactNode
  status: "pending" | "success" | "failed"
}) {
  return (
    <div
      className={`w-[300px] flex items-center bg-frame-toast bg-center bg-no-repeat bg-[length:100%_100%] py-2 pl-5 gap-3`}
    >
      <div className="flex justify-center items-center">{logo}</div>

      <div className="flex-1 flex flex-col items-start leading-none gap-0.5 text-inherit w-full">
        <p
          className={`text-sm font-bold line-clamp-1 text-inherit flex justify-center items-center gap-3`}
        >
          {title}
        </p>

        {description && <p className={`text-xs font-medium line-clamp-1 text-inherit`}>
          {description}
        </p>}
      </div>
    </div>
  )
}
