import { useState } from "react";
import toast from "react-hot-toast";

import AppModal from "../shared/AppModal";
import AppLoading from "../shared/AppLoading";
import {
  PLAY_COTTON_MINING_SOUND,
  PLAY_METAL_MINING_SOUND,
  PLAY_WOOD_MINING_SOUND,
} from "../../../utils/audio";
import { useMUD } from "../../../useMUD";
import { SkillType } from "../../../constant";
import { ValueOf } from "../../../utils/tools";
import { useAllowance } from "../../hooks/useAllowence";

export default function StartProductForm({
  productType,
  oreLeft,
  woodLeft,
  seedsLeft,
  handleCloseModal,
  activeProducts,
}: {
  productType: string;
  oreLeft: number;
  woodLeft: number;
  seedsLeft: number;
  handleCloseModal: () => void;
  activeProducts: {
    skillType: ValueOf<typeof SkillType>;
    skillSequence: number;
  }[];
}) {
  const { allowanceAmount, writeAllowance } = useAllowance();
  const [batchSize, setBatchSize] = useState<string>("");
  const [pending, setPending] = useState<boolean>(false);
  const { player, energy: {balance, refetch}, writeWorldContract } = useMUD();

  console.log({ balance, allowanceAmount });

  async function startProductAction() {
    if (!player) return toast.error("Please login first!");
    if (!batchSize)
      return toast.error("Please input the amount you want to create!");

    if (Number(batchSize) > balance)
      return toast.error(
        "You don't have enough energy coin, please claim some first!"
      );
    if (Number(batchSize) > Math.floor(allowanceAmount)) {
      writeAllowance();
      toast.error("You need to approve the energy token first!");
      handleCloseModal();
      return;
    }
    setPending(true);

    // If the player does mining
    if (productType === "ore") {
      if (Number(batchSize) > oreLeft)
        return toast.error("Not enough ore left!");

      if (
        activeProducts.some(({ skillType }) => skillType === SkillType.MINING)
      )
        return toast.error(
          "Please wait for the end of the mining process and harvest first!"
        );

      writeWorldContract(
        "app__uniApiStartCreation",
        [SkillType.MINING, BigInt(player.id), 0, 301, Number(batchSize)],
        ({ status }) => {
          if (status) {
            PLAY_METAL_MINING_SOUND();
            handleCloseModal();
            setBatchSize("");
            refetch();
          }
          setPending(false);
        }
      );
    }

    // If the player does wood cutting
    else if (productType === "wood") {
      if (Number(batchSize) > woodLeft)
        return toast.error("Not enough wood left!");

      if (
        activeProducts.some(
          ({ skillType }) => skillType === SkillType.WOODCUTTING
        )
      )
        return toast.error(
          "Please wait for the end of the wood cutting process and harvest first!"
        );

      writeWorldContract(
        "app__uniApiStartCreation",
        [SkillType.WOODCUTTING, BigInt(player.id), 0, 200, Number(batchSize)],
        ({ status }) => {
          if (status) {
            PLAY_WOOD_MINING_SOUND();
            setBatchSize("");
            handleCloseModal();
            refetch();
          }
          setPending(false);
        }
      );
    }

    // If the player does seed farming
    else if (productType === "seed") {
      if (Number(batchSize) > seedsLeft)
        return toast.error("Not enough cotton seed left!");

      const hasActiveFarming = activeProducts.some(
        ({ skillType }) => +skillType === SkillType.FARMING
      );
      let farmingSeq = 0;
      if (hasActiveFarming) {
        const activeFarming = activeProducts.filter(
          ({ skillType }) => +skillType === SkillType.FARMING
        );
        if (activeFarming.length === 2) {
          //maxV Number of process system
          return toast.error(
            "Please wait for the end of the seeding process and harvest first!"
          );
        } else {
          const activeFarmingSequence = activeFarming[0].skillSequence;
          // console.log({ activeFarmingSequence });

          farmingSeq = activeFarmingSequence === 0 ? 1 : 0;
        }
      }
      writeWorldContract(
        "app__uniApiStartProduction",
        [
          SkillType.FARMING,
          BigInt(player.id),
          farmingSeq,
          102,
          Number(batchSize),
        ],
        ({ status }) => {
          if (status) {
            PLAY_COTTON_MINING_SOUND();
            setBatchSize("");
            handleCloseModal();
            refetch();
          }
          setPending(false);
        }
      );
    }
  }

  function getProductName() {
    if (productType === "ore") return "Ore";
    else if (productType === "wood") return "Wood";
    else if (productType === "seed") return "Seeds";
    else return "Unknown";
  }

  return (
    <AppModal frame="bg-frame-prod">
      <div className="w-96 min-h-48 flex flex-col justify-between items-center text-2xl text-white">
        {!pending ? (
          <>
            <div className="flex flex-col items-center pt-8 pb-5 gap-2">
              <div className="flex items-center gap-1">
                <label htmlFor="newPlayerName">{getProductName()}: </label>

                <input
                  id="newPlayerName"
                  className="w-32 bg-transparent text-center border-b-[1px] border-white outline-none"
                  type="text"
                  value={batchSize}
                  onChange={(e) => {
                    if (/^\d*$/.test(e.target.value))
                      setBatchSize(e.target.value);
                  }}
                />
              </div>

              <p>
                Costs{" "}
                {["ore", "wood"].includes(productType)
                  ? batchSize
                  : Number(batchSize) * 5}{" "}
                energy token
              </p>

              {productType === "ore" && <p>Can harvest {batchSize} copper</p>}
              {productType === "wood" && <p>Can harvest {batchSize} wood</p>}
              {productType === "seed" && <p>Can harvest {batchSize} cotton</p>}

              <p>
                {["ore", "wood"].includes(productType)
                  ? Number(batchSize) * 3
                  : Number(batchSize) * 15}{" "}
                seconds duration
              </p>
            </div>

            <div className="flex w-full items-center">
              <div
                className="flex-1 bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] text-center cursor-pointer py-3"
                onClick={startProductAction}
              >
                Create
              </div>

              <div
                className="flex-1 bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] text-center cursor-pointer py-3"
                onClick={() => {
                  handleCloseModal();
                  setBatchSize("");
                }}
              >
                Cancel
              </div>
            </div>
          </>
        ) : (
          <AppLoading size={100} />
        )}
      </div>
    </AppModal>
  );
}
