import { defineComponentSystem, getComponentValueStrict } from "@latticexyz/recs";
import { decodeEntity } from "@latticexyz/store-sync/recs";
import { PhaserLayer } from "../createPhaserLayer";
import { Sprites, TILE_WIDTH, TILE_HEIGHT } from "../constants";
import { getLocalCoordinate } from "../../../utils/math";
import { useStore } from "../../../useStore";
import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { mapResource } from "../../../utils/tools";

export function createIslandCoordinateSystem(layer: PhaserLayer) {
  const {
    world,
    scenes: {
      Main: { phaserScene, config },
    },
    networkLayer: {
      components: { MapLocation },
    },
  } = layer;

  defineComponentSystem(world, MapLocation, ({entity}) => {
    const {resourcesItemIds, resourcesQuantities, occupiedBy, gatheredAt} = getComponentValueStrict(MapLocation, entity);
    const onChainCoordinate = decodeEntity({ x: "uint32", y: "uint32" }, entity);
    const localCoordinate = getLocalCoordinate(onChainCoordinate.x, onChainCoordinate.y);
    // const {x, y} = tileCoordToPixelCoord(localCoordinate, TILE_WIDTH, TILE_HEIGHT);
    const resources = mapResource(resourcesItemIds, resourcesQuantities);
    // const text = `Coordinate: (x: ${onChainCoordinate.x}, y: ${onChainCoordinate.y})\nResources: (Ore: ${resources.ore}, Wood: ${resources.wood}, Seed: ${resources.seed})\n${occupiedBy ? `Occupied By: Player ${occupiedBy}`:  'Click to Claim Island' }`;
    // const {player,  externalWalletClient, externalWorldContract} = useStore.getState();
    // const claimBtn = phaserScene.add
    //   .text(x, y - 50, text, {
    //     fontSize: "16px",
    //     color: "#000",
    //     backgroundColor: "#fff",
    //     padding: {
    //       x: 5,
    //       y: 5,
    //     },
    //   })
    //   .setOrigin(0.5, 0.5)
    //   .setDepth(3)
    //   .setVisible(false)
    //   .setInteractive({
    //     useHandCursor: true,
    //   })
    //   .on("pointerdown", async() => {
    //     if(occupiedBy) return toast.error('Island already occupied a player');
    //     if(!player) return toast.error('Player not found');
    //     const tx = await externalWorldContract.write.app__playerClaimIsland([BigInt(player.id), onChainCoordinate.x, onChainCoordinate.y], {
    //       account: externalWalletClient.account,
    //     });
    //     await waitForTransaction(tx);
    //   });

    // phaserScene.add.tween({
    //   targets: claimBtn,
    //   duration: 1000,
    //   y: claimBtn.y - 5,
    //   repeat: -1,
    //   yoyo: true,
    // });
    phaserScene.add
    .sprite(localCoordinate.x, localCoordinate.y, Sprites.ISLAND)
    .setTexture(config.sprites[Sprites.ISLAND].assetKey, config.sprites[Sprites.ISLAND].frame)
    .setDepth(2)
    .setInteractive({
      useHandCursor: true,
    })
    .on("pointerdown", () => {
      useStore.setState({ clickedIsland: {resources,  occupiedBy: Number(occupiedBy), gatheredAt: Number(gatheredAt), onChainCoordinate} });
      // if(!claimBtn.visible) phaserCamera.centerOn(x, y).zoomTo(1.5, 2000, "Linear", true);
      // else phaserCamera.centerOn(player.island.x, player.island.y).zoomTo(0.5, 2000, "Linear", true);
      // claimBtn.setVisible(!claimBtn.visible);
    });
});
}
