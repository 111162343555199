import { create } from "zustand";
import { NetworkLayer } from "./layers/network/createNetworkLayer";
import { PhaserLayer } from "./layers/phaser/createPhaserLayer";
import { WalletClient } from "viem";

export type Store = {
  networkLayer: NetworkLayer | null;
  phaserLayer: PhaserLayer | null;
  externalWalletClient: WalletClient | null;
  writeWorldContract: (funName: string, args: any[], callback?: ({status, error, receipt}: {status: boolean, error: unknown, receipt: unknown}) => void) => void;
  player: {id: number, name: string, island: {x: number, y: number}, level: number, experience: number } | null;
  clickedIsland: {resources: any, occupiedBy: number, gatheredAt: number, onChainCoordinate: {x: number, y: number}} | null;
  energy: {balance: number, refetch: () => void};
};

export const useStore = create<Store>(() => ({
  networkLayer: null,
  phaserLayer: null,
  player: null,
  externalWalletClient: null,
  writeWorldContract: () => {},
  clickedIsland: null,
  energy: { balance: 0, refetch: () => {}},
}));


