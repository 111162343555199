export const WORLD_SIZE = 150000;
export const SCALING_FACTOR = 10;

export function between(x: number, min: number, max: number) {
  return x >= min && x <= max;
}

/**
 * Coordinates in the contract/backend are represented as u32 with a maximum value of 4294967295
 * center point is (2,147,483,647, 2,147,483,647)
 */
export function getLocalCoordinate(x?: number, y?: number) {
  if (!x || !y) return { x: 0, y: 0 };

  return {
    x: (x - 2147483647) / SCALING_FACTOR,
    y: (y - 2147483647) / SCALING_FACTOR,
  };
}

export function getWorldCoordinate(x: number, y: number) {
  return {
    x: x * SCALING_FACTOR + (2147483647 - WORLD_SIZE * 0.5),
    y: y * SCALING_FACTOR + (2147483647 - WORLD_SIZE * 0.5),
  };
}

export function getWorldDistance(distance: number) {
  return distance * SCALING_FACTOR;
}

export function getUniqueId(id1: string, id2: string) {
  return [id1, id2].sort().join("") || "";
}

export function getDirectionOfIsland(x: number, y: number) {
  if (x === 0) {
    if (y > 0) {
      return "N";
    } else {
      return "S";
    }
  } else if (y === 0) {
    if (x > 0) {
      return "E";
    } else {
      return "W";
    }
  } else if (x > 0) {
    if (y > 0) {
      return "NE";
    } else {
      return "SE";
    }
  } else if (x < 0) {
    if (y > 0) {
      return "NW";
    } else {
      return "SW";
    }
  } else {
    return "O";
  }
}
