import { useEffect, useState } from "react";

import AppLoading from "./AppLoading";
import { useMUD } from "../../../useMUD";
import { useStore } from "../../../useStore";
import { HasValue, getComponentValueStrict, Has } from "@latticexyz/recs";
import { useEntityQuery } from "@latticexyz/react";
import { formatResource } from "../../../utils/tools";
import { decodeEntity } from "@latticexyz/store-sync/recs";

// import { getNftByOwner, suiPlayerInfo } from "@/actions/player.action";
// import { getLeaderboard } from "@/actions/quest.action";

export function IslandTopbar() {
  const {
    networkLayer: {
      components: { Player, PlayerInventory, Ship },
    },
    clickedIsland,
  } = useMUD();
  const playerEntity = useEntityQuery([
    HasValue(Player, { claimedIslandX: clickedIsland?.onChainCoordinate.x, claimedIslandY: clickedIsland?.onChainCoordinate.y }),
  ]);
  const { name, owner, experience, level} = playerEntity.length > 0 ? getComponentValueStrict(Player, playerEntity[0]) : {
    name: '',
    owner: '',
    experience: 0,
    level: 0
  };
  const {id} = playerEntity[0]?decodeEntity({"id": "uint256"}, playerEntity[0]):0; //extracting id of clicked island
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [islandOwnerPoints, setIslandOwnerPoints] = useState<number>(0);
  const [avatarPending, setAvatarPending] = useState<boolean>(false);

  const values = [
    { id: 0, title: "Reward Points", count: islandOwnerPoints, iconUrl: "/frontend/icon/points.png" },
    { id: 1, title: "Exp", count: experience, iconUrl: "/frontend/icon/points.png" },
    { id: 2, title: "Level", count: level, iconUrl: "/frontend/icon/points.png" },
  ];

  const InventoryEntities = useEntityQuery([
    Has(PlayerInventory), //get all playerinventory
    // HasValue(PlayerInventory, {playerId: player?.id}),
  ]);

  const ShipEntities = useEntityQuery([HasValue(Ship,{"playerId":id})]);

  const inventory = InventoryEntities.map((entity) => {
    const { playerId } = decodeEntity({"playerId": "uint256", "inventoryIndex": "uint64"}, entity);
    const { inventoryItemId, inventoryQuantity} = getComponentValueStrict(PlayerInventory, entity);
    if(Number(playerId) !== Number(id)) return null;  //check for clicked island player id with all the other player id for extracting resources
    return {
      inventoryItemId,
      inventoryQuantity,
    }
  }).filter((data) => data);
  // console.log(ShipEntities.length);

  const { ore: oreLeft, wood: woodLeft, seed: seedsLeft } = formatResource(inventory);
  const resources = [
    { id: 1, title: "Ore", count: (clickedIsland?.resources.ore+oreLeft) || 0, iconUrl: "/frontend/resource/ore.png" },
    { id: 0, title: "Wood", count: (clickedIsland?.resources.wood+woodLeft) || 0, iconUrl: "/frontend/resource/wood.png" },
    { id: 2, title: "Seeds", count: (clickedIsland?.resources.seed+seedsLeft) || 0, iconUrl: "/frontend/resource/seeds.png" },
    { id: 3, title: "Ships", count: ShipEntities.length || 0, iconUrl: "/frontend/resource/ship.png" },
  ];

  // useEffect(() => {
  //   (async () => {
  //     if (!clickedIsland?.occupiedBy) return setAvatarUrl("");
  //     setAvatarPending(true);

  //     // Prepare avatar image
  //     const nft = await getNftByOwner({ owner });
  //     if (nft && nft.imageUrl) setAvatarUrl(nft.imageUrl);
  //     setAvatarPending(false);

  //     // Prepare reward points
  //     const pairs = await getLeaderboard();
  //     const ps = pairs.find(
  //   (async () => {
  //     if (!clickedIsland?.occupiedBy) return setAvatarUrl("");
  //     setAvatarPending(true);

  //     // Prepare avatar image
  //     const nft = await getNftByOwner({ owner });
  //     if (nft && nft.imageUrl) setAvatarUrl(nft.imageUrl);
  //     setAvatarPending(false);

  //     // Prepare reward points
  //     const pairs = await getLeaderboard();
  //     const ps = pairs.find(
  //       ({ wallet, nft_id }: { wallet: string; nft_id: string }) =>
  //         wallet === playerInfo.owner || (nft && nft_id === nft.id)
  //     );
  //     setIslandOwnerPoints(ps ? ps.totalRewardPoints : 0);
  //   })();
  // }, [clickedIsland?
  //       ({ wallet, nft_id }: { wallet: string; nft_id: string }) =>
  //         wallet === playerInfo.owner || (nft && nft_id === nft.id)
  //     );
  //     setIslandOwnerPoints(ps ? ps.totalRewardPoints : 0);
  //   })();
  // }, [clickedIsland?.occupiedBy]);

  if(!clickedIsland) return null;

  return (
    <div className="fixed top-2 left-1/2 -translate-x-1/2 w-3/5 h-min flex flex-col items-center bg-frame-topbar bg-center bg-no-repeat bg-cotain bg-[length:100%_100%] px-16 py-4 gap-2 z-40">
      <img
        className="absolute top-5 right-9 cursor-pointer"
        src="/frontend/icon/x-mark.png"
        alt="x-icon"
        width={36}
        height={36}
        onClick={() => useStore.setState({ clickedIsland: null })}
      />

      <div className="w-[95%] h-1/2 flex justify-between items-center">
        <div className="flex items-center gap-2">
          {!avatarPending ? (
            <img
              className="rounded-full"
              src={avatarUrl ? avatarUrl : "/frontend/icon/avatar.png"}
              alt="avatar-icon"
              width={48}
              height={48}
            
            />
          ) : (
            <AppLoading size={48} />
          )}

          <h1 className="text-xl text-white">
            {name ? `${name}'s island` : "Island can be claimed"}
          </h1>
        </div>

        {values.map((value) => (
          <div key={value.id} className="flex items-center gap-1">
            <img src={value.iconUrl} alt="points" width={50} height={50} />
            <p className="text-white">
              {value.title}: {value.count}
            </p>
          </div>
        ))}
      </div>

      <div className="w-full h-1/2 flex justify-evenly items-center gap-4">
        {resources.map((resource) => (
          <div key={resource.id} className="flex items-center gap-1">
            <img src={resource.iconUrl} alt="resource-icon" width={40} height={40} />
            <p className="text-white">
              {resource.title}: {resource.count}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
