import { useState } from "react";
import toast from "react-hot-toast";

import AppModal from "../shared/AppModal";
import AppLoading from "../shared/AppLoading";

import { PLAY_BUTTON_CLICK_SOUND, PLAY_CLAIM_ISLAND_HARVEST_SOUND } from "../../../utils/audio";
import { useMUD } from "../../../useMUD";
import { useStore } from "../../../useStore";

export default function ClaimIslandForm() {
  const [pending, setPending] = useState<boolean>(false);
  const {
    clickedIsland,
    writeWorldContract,
    player
  } = useMUD();

  async function claimIslandAction() {
    setPending(true);
    if(!player) return toast.error('Player not found');

    writeWorldContract("app__playerClaimIsland", [BigInt(player.id), clickedIsland?.onChainCoordinate.x, clickedIsland?.onChainCoordinate.y], ({status}) => {
      if(status === true) {
        PLAY_CLAIM_ISLAND_HARVEST_SOUND();
        useStore.setState({player:{
          ...player,
          island: {
            ...player.island,
            x: clickedIsland?.onChainCoordinate.x||0,
            y: clickedIsland?.onChainCoordinate.y||0
          }}
        }); //for showing just after claim
        useStore.setState({ clickedIsland: null });
      }
     setPending(false);
   });

  }
  if(clickedIsland?.occupiedBy || player?.island.x || player?.island.y) return null;

  return (
    <AppModal frame="bg-frame-prod">
      <div className="w-72 min-h-40 relative flex flex-col justify-between items-center text-2xl text-white gap-8">
        {!pending ? (
          <>
            <img
              className="absolute top-5 right-4 cursor-pointer"
              src="/frontend/icon/x-mark.png"
              alt="x-icon"
              width={32}
              height={32}
              onClick={() => useStore.setState({ clickedIsland: null })}
            />

            <div className="flex flex-col items-center pt-10 gap-3">
              <p>Are you sure to</p>
              <p>claim this island?</p>
              <p className="text-center">
                X: {clickedIsland?.onChainCoordinate.x} <br/> Y: {clickedIsland?.onChainCoordinate.y}
              </p>
            </div>

            <button
              className="w-full bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] text-center cursor-pointer py-4"
              type="button"
              onClick={() => {
                PLAY_BUTTON_CLICK_SOUND();
                claimIslandAction();
              }}
            >
              Claim
            </button>
          </>
        ) : (
          <AppLoading size={85} />
        )}
      </div>
    </AppModal>
  );
}
