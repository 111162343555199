// GENERATED CODE - DO NOT MODIFY BY HAND

export enum Tileset {
  SmallIsland1 = 0,
  SmallIsland2 = 1,
  SmallIsland3 = 2,
  Ocean = 3,
}
export enum TileAnimationKey {}
export const TileAnimations: { [key in TileAnimationKey]: number[] } = {};
