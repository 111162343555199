import { useEffect } from "react";
import { useStore } from "../useStore";
import { PhaserLayer } from "./ui/PhaserLayer";
import { UIRoot } from "./ui/UIRoot";
import { useNavigate } from "react-router-dom";
import mudConfig from "../mud.config";
import IWorldAbi from "../IWorld.abi.json";
import { useIsMobile } from "./hooks/useIsMobile";

export const Game = () => {
  const navigate = useNavigate();
  const networkLayer = useStore((state) => state.networkLayer);
  const externalWallet = useStore((state) => state.externalWalletClient);
  console.log({ externalWallet });
  console.log({ useragent: navigator.userAgent });
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!networkLayer) return;
    console.log(import.meta.env);

    // https://vitejs.dev/guide/env-and-mode.html
    if (import.meta.env.DEV) {
      import("@latticexyz/dev-tools").then(({ mount: mountDevTools }) =>
        mountDevTools({
          config: mudConfig,
          publicClient: networkLayer.network.publicClient,
          walletClient: networkLayer.network.walletClient,
          latestBlock$: networkLayer.network.latestBlock$,
          storedBlockLogs$: networkLayer.network.storedBlockLogs$,
          worldAddress: "0x1e6678e0af0aa0220a01fca979fa3612e3e4e2ce",
          worldAbi: IWorldAbi,
          recsWorld: networkLayer.world,
        })
      );
    }
  }, [networkLayer]);

  useEffect(() => {
    if (!externalWallet) navigate("/");
  }, [externalWallet]);

  if (isMobile)
    return (
      <div className="w-full h-screen relative flex justify-center items-center">
        <h1 className="relative text-2xl text-white text-center tracking-wider px-16 z-40">
          Mobile support not available. Please use a desktop or tablet.
        </h1>

        <img
          className="absolute w-full h-full z-0 object-cover brightness-50"
          src="/frontend/onboarding/cover.png"
          alt="cover-img"
          width={1024}
          height={768}
        />
      </div>
    );
  return (
    <div style={{ width: "100vw", height: "100vh", position: "relative" }}>
      <PhaserLayer networkLayer={networkLayer} />
      <UIRoot />
    </div>
  );
};
