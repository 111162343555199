export const SOUNDS = {
  ADD_ROSTER_SOUND: new Audio('assets/audio/add-to-roster.mp3'),
  BATTLE_SOUND: new Audio('assets/audio/battle.mp3'),
  BUTTON_CLICK_SOUND: new Audio('assets/audio/button-click.mp3'),
  CLAIM_ENERGY_SOUND: new Audio('assets/audio/claim-energy-token.mp3'),
  CLAIM_ISLAND_HARVEST_SOUND: new Audio('assets/audio/claim-island-harvest.mp3'),
  COTTON_MINING_SOUND: new Audio('assets/audio/cotton-mining.mp3'),
  CRAFTING_SOUND: new Audio('assets/audio/crafting.mp3'),
  LOSER_SOUND: new Audio('assets/audio/loser.mp3'),
  METAL_MINING_SOUND: new Audio('assets/audio/metal-mining.mp3'),
  OPEN_CHEST_SOUND: new Audio('assets/audio/open-chest.mp3'),
  SAILING_SOUND: new Audio('assets/audio/sailing.mp3'),
  TRANSFER_INVENTORY_SOUND: new Audio('assets/audio/transfer-inventory.mp3'),
  UNASSIGNED_ROSTER_SOUND: new Audio('assets/audio/unassigned-from-roster.mp3'),
  WINNER_SOUND: new Audio('assets/audio/winner.mp3'),
  WOOD_MINING_SOUND: new Audio('assets/audio/wood-mining.mp3'),
};

const PLAY_ADD_ROSTER_SOUND= () => SOUNDS.ADD_ROSTER_SOUND.play();
const PLAY_BATTLE_SOUND= () => SOUNDS.BATTLE_SOUND.play();
const PLAY_BUTTON_CLICK_SOUND= () => SOUNDS.BUTTON_CLICK_SOUND.play();
const PLAY_CLAIM_ENERGY_SOUND= () => SOUNDS.CLAIM_ENERGY_SOUND.play();
const PLAY_CLAIM_ISLAND_HARVEST_SOUND= () => SOUNDS.CLAIM_ISLAND_HARVEST_SOUND.play();
const PLAY_COTTON_MINING_SOUND= () => SOUNDS.COTTON_MINING_SOUND.play();
const PLAY_CRAFTING_SOUND= () => SOUNDS.CRAFTING_SOUND.play();
const PLAY_LOSER_SOUND= () => SOUNDS.LOSER_SOUND.play();
const PLAY_METAL_MINING_SOUND= () => SOUNDS.METAL_MINING_SOUND.play();
const PLAY_OPEN_CHEST_SOUND= () => SOUNDS.OPEN_CHEST_SOUND.play();
const PLAY_SAILING_SOUND= () => SOUNDS.SAILING_SOUND.play();
const PLAY_TRANSFER_INVENTORY_SOUND= () => SOUNDS.TRANSFER_INVENTORY_SOUND.play();
const PLAY_UNASSIGNED_ROSTER_SOUND= () => SOUNDS.UNASSIGNED_ROSTER_SOUND.play();
const PLAY_WINNER_SOUND= () => SOUNDS.WINNER_SOUND.play();
const PLAY_WOOD_MINING_SOUND= () => SOUNDS.WOOD_MINING_SOUND.play();

export {
  PLAY_ADD_ROSTER_SOUND,
  PLAY_BATTLE_SOUND,
  PLAY_BUTTON_CLICK_SOUND,
  PLAY_CLAIM_ENERGY_SOUND,
  PLAY_CLAIM_ISLAND_HARVEST_SOUND,
  PLAY_COTTON_MINING_SOUND,
  PLAY_CRAFTING_SOUND,
  PLAY_LOSER_SOUND,
  PLAY_METAL_MINING_SOUND,
  PLAY_OPEN_CHEST_SOUND,
  PLAY_SAILING_SOUND,
  PLAY_TRANSFER_INVENTORY_SOUND,
  PLAY_UNASSIGNED_ROSTER_SOUND,
  PLAY_WINNER_SOUND,
  PLAY_WOOD_MINING_SOUND
};
