import { useSyncStatus } from "../../hooks/useSyncStatus";

export function SyncStatus() {
  const { latestSyncedBlockNumber, syncStatus, statusColor } = useSyncStatus();

  return (
    <div className="fixed text-white bg-ss-bg-0 top-4 left-1/2 -translate-x-1/2 rounded-lg p-4 w-72 h-20 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] ">
      <div className={`flex items-center justify-center`}>
        <div
          className={`animate-pulse rounded-full w-4 h-4 mr-2`}
          style={{
            backgroundColor: statusColor,
          }}
        />
        <span className="text-lg text-center">
          {syncStatus === "ok" ? "Synced" : syncStatus === "concerning" ? "Syncing" : "Desynced (Consider Reloading)"}
        </span>
      </div>
      <p className="text-center">Latest Block : {latestSyncedBlockNumber}</p>
    </div>
  );
}
