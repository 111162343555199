import { useState } from "react";
import { useStore } from "../../useStore";
import { LoadingScreen } from "./LoadingScreen";
import { Wrapper } from "./Wrapper";
import { NavItems } from "./shared/NavItems";
import { ClickWrapper } from "./theme/ClickWrapper";
import { ActionQueue } from "./shared/ActionQueue";
import { SyncStatus } from "./shared/SyncStatus";
import { IslandTopbar } from "./shared/IslandTopbar";
import { IslandMenu } from "./menus/IslandMenu";
import { CreatePlayerForm } from "./form/CreatePlayerForm";
import ClaimIslandForm from "./form/ClaimIslandForm";
import { DailyQuestsMenu } from "./menus/DailyQuestsMenu";
import { NewbieQuestsMenu } from "./menus/NewbieQuestsMenu";
// import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { getLocalCoordinate } from "../../utils/math";
// import { TILE_WIDTH, TILE_HEIGHT } from "../../layers/phaser/constants";
import toast from "react-hot-toast";
import ShipsMenu from "./menus/ShipsMenu";

export const UIRoot = () => {
  const layers = useStore((state) => {
    return {
      networkLayer: state.networkLayer,
      phaserLayer: state.phaserLayer,
      player: state.player,
      clickedIsland: state.clickedIsland,
    };
  });
  const [islandMenuFlag, setIslandMenuFlag] = useState<boolean>(false);
  const [dailyQuestsMenuFlag, setDailyQuestsMenuFlag] =
    useState<boolean>(false);
  const [newbieQuestsMenuFlag, setNewbieQuestsMenuFlag] =
    useState<boolean>(false);
  const [shipsMenuFlag, setShipsMenuFlag] = useState<boolean>(false);

  if (!layers.networkLayer || !layers.phaserLayer) return <></>;
  const {
    phaserLayer: {
      scenes: {
        Main: { camera },
      },
    },
    player,
    clickedIsland,
  } = layers;
  // Click ships card
  function handleShipsClicked() {
    if (!player) return toast.error("Please login first!");

    setShipsMenuFlag((prev) => !prev);
    setIslandMenuFlag(false);
    return;
  }

  return (
    <Wrapper>
      <ClickWrapper style={{ position: "relative", zIndex: 1000 }}>
        <LoadingScreen />
        {!layers.player && <CreatePlayerForm />}
        <NavItems
          getCombatHistoryClicked={() => {}}
          getDailyQuestsClicked={() => {
            if (newbieQuestsMenuFlag) return;
            setDailyQuestsMenuFlag(true);
          }}
          getNewbieQuestsClicked={() => {
            if (dailyQuestsMenuFlag) return;
            setNewbieQuestsMenuFlag(true);
          }}
          getIslandCardClicked={() => {
            if (player) {
              const localCoordinate = getLocalCoordinate(
                player.island.x,
                player.island.y
              );
              // console.log("player",{x:player.island.x, y:player.island.y});
              // const {x, y} = tileCoordToPixelCoord(localCoordinate, TILE_WIDTH, TILE_HEIGHT);
              camera.centerOn(localCoordinate.x, localCoordinate.y);
              camera.phaserCamera.zoomTo(1, 2000, "Linear", true);
              // .zoomTo(1, 2000, "Sine.easeInOut", true);
              // layers.phaserLayer?.scenes.Main.phaserScene.tweens.add({
              //   targets: phaserCamera,
              //   scrollX: localCoordinate.x,
              //   scrollY: localCoordinate.y,
              //   duration: 4000, // Duration of the slide in milliseconds
              //   ease: 'Power2', // Easing function for smooth movement (options include Power2, Sine, etc.)
              // });
            }
            setIslandMenuFlag((prev) => !prev);
          }}
          getShipsCardClicked={handleShipsClicked}
          onRefreshGame={() => {}}
        />
        {clickedIsland && !clickedIsland?.occupiedBy && <ClaimIslandForm />}
        <IslandTopbar />
        <ActionQueue />
        <SyncStatus />
        {islandMenuFlag && <IslandMenu />}
        {shipsMenuFlag && (
          <ShipsMenu closeShipsMenu={() => setShipsMenuFlag(false)} />
        )}
        {/* <ShipsMenu getRosterPosition={getRosterPosition} closeShipsMenu={() => setShipsMenuFlag(false)} />
       <CombatForm
          getRosterPosition={getRosterPosition}
          handleAfterCombat={(combatId) => {
            setCurrentCombatId(combatId);
            setCombatResultFlag(true);
          }}
          handleCloseModal={() => {
            updateSailing();
            setCombatFormFlag(false);
            EventBus.emit(GameEventType.ToggleInteraction, true);
          }}
        />
        <CombatResultForm combatId={currentCombatId} handleCloseModal={() => {
          setCombatResultFlag(false);
          EventBus.emit(GameEventType.ToggleInteraction, true);
        }} />
         <CombatHistoryMenu
          showCombatResults={(combatId) => {
            setCurrentCombatId(combatId);
            setCombatResultFlag(true);
          }}
          handleCloseMenu={() => {
            setCombatHistoryFlag(false);
            EventBus.emit(GameEventType.ToggleInteraction, true);
          }}
        /> */}
        {dailyQuestsMenuFlag && (
          <DailyQuestsMenu
            handleCloseMenu={() => {
              setDailyQuestsMenuFlag(false);
            }}
          />
        )}
        {newbieQuestsMenuFlag && (
          <NewbieQuestsMenu
            handleCloseMenu={() => {
              setNewbieQuestsMenuFlag(false);
            }}
          />
        )}
      </ClickWrapper>
    </Wrapper>
  );
};
