import { useEffect, useState } from "react";
import AppLoading from "../shared/AppLoading";

const socialQuestsData = {
   0: {
      name: "Playtest",
      description: "Sign up to become the selected 1k playtesters #1",
      status: "Remaining",
      link: "https://forms.gle/SvwvHY1E6emC2dmJ6",
    },
    1: {
      name: "Follow",
      description: "Follow Infinite Seas on X/Twitter",
      status: "Remaining",
      link: "https://x.com/intent/follow?screen_name=InfiniseasDev",
    },
    2: {
      name: "Like",
      description: "Like Infinite Seas Odyssey Tweet",
      status: "Remaining",
      link: "https://x.com/intent/like?tweet_id=1854564346678198566",
    },
    3: {
      name: "Comment",
      description: "Comment on Infinite Seas Odyssey Tweet",
      status: "Remaining",
      link: "https://x.com/intent/post?in_reply_to=1854564346678198566",
    },
    4: {
      name: "Retweet",
      description: "Retweet Infinite Seas Odyssey Tweet",
      status: "Remaining",
      link: "https://x.com/intent/retweet?tweet_id=1854564346678198566",
    },
    5: {
      name: "Bookmark",
      description: "Bookmark Infinite Seas Odyssey Tweet",
      status: "Remaining",
      link: "https://x.com/InfiniseasDev/status/1854564346678198566",
    },
    6: {
      name: "Telegram",
      description: "Join Infinite Seas Telegram",
      status: "Remaining",
      link: "https://t.me/infiniteseasdev",
    },
}

export function QuestsMenu() {
  const [socialQuestsList, setSocialQuestsList] = useState(Object.values(socialQuestsData));

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        background: "url('frontend/onboarding/cover.png')",
        backgroundSize: "cover",
      }}
      className="flex flex-col justify-center items-center"
    >
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full md:w-[666px] z-40 bg-paper-row bg-center bg-no-repeat bg-[length:100%_100%] text-white p-6 md:p-12 gap-4 md:gap-6">
        <div className="w-full h-full bg-frame-prod bg-center bg-no-repeat bg-[length:100%_100%]">
          <h1 className="text-center text-xl md:text-2xl py-6">
            Story Odyssey Quests Phase 0
          </h1>

          <div className="flex flex-col items-center min-h-72 overflow-y-auto px-4 md:px-8 pb-11 gap-4">
            <div className="flex justify-center">
              <img
                src="/frontend/quest/badge-icon-2.svg"
                alt={"Badge Icon"}
                className="rounded-full"
                width={120}
                height={120}
              />
            </div>
             
            {/* Note Under Badge */}
            <p className="text-sm text-center mx-4">
              We're rolling out multiple quest phases to gather your invaluable feedback before mainnet. Your participation helps us craft the best gaming experience possible. Unlock Badge after Phase 4.
            </p>
             
            {socialQuestsList.map((quest, id) => (
              <div
                key={id}
                className="w-full flex justify-between items-center px-4"
              >
                <p className="text-base md:text-xl">{quest.description}</p>
                <div className="relative w-8 h-8">
                  <a
                    href={quest.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-12 h-8 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex justify-center items-center"
                  >
                    Start
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
