// This form can help players transfer their inventory but only form themselves.
// These is a transaction UI but there is no API for buying/selling inventory with other players.



import toast from "react-hot-toast";

import { TxToast } from "../shared/TxToast";
import { PLAY_BUTTON_CLICK_SOUND, PLAY_TRANSFER_INVENTORY_SOUND } from "../../../utils/audio";
import { useMUD } from "../../../useMUD";
import { toBigInt } from "ethers";

export default function TransferResourceForm({
  rosterSequenceNumber,
  col1ShipId,
  col2ShipId,
  col2IslandFlag,
  transferPath,
  getRosterPosition,
}: {
  rosterSequenceNumber: number;
  col1ShipId: string;
  col2ShipId: string;
  col2IslandFlag: boolean;
  transferPath: any[];
  getRosterPosition: {
    updatedCoordinates:{
      x: number;
      y: number;},
    updatedSailSegment: number,
    updatedAt: number
  }[];  
}) {

  const {player, writeWorldContract} = useMUD();
  // This action can transfer inventory among ships.
  // Only ships belong to one roster can execute this action.
  async function transferBetweenShipsAction() {
    PLAY_BUTTON_CLICK_SOUND();
    if (!player) return toast.error("Player not found");
    if (!transferPath.some((path) => path.quantity !== 0)) return toast.error("No tranfer found!");

    const forwardPath: any[] = transferPath.map((path) => (path.quantity > 0 ? path.quantity : 0));
    const reversePath: any[] = transferPath.map((path) => (path.quantity < 0 ? Math.abs(path.quantity) : 0));

    toast.loading("Inventory transferring, it may take a while...");
    if (forwardPath.some((quantity) => quantity !== 0)) {
      writeWorldContract("app__rosterTransferShipInventory",[
        BigInt(player.id),
        rosterSequenceNumber,
        BigInt(col1ShipId),
        toBigInt(col2ShipId),
        forwardPath
      ],()=>{PLAY_TRANSFER_INVENTORY_SOUND();});
    }
    if (reversePath.some((quantity) => quantity !== 0)) {
      writeWorldContract("app__rosterTransferShipInventory",[
        BigInt(player.id),
        rosterSequenceNumber,
        BigInt(col2ShipId),
        BigInt(col1ShipId),
        reversePath
      ],()=>{PLAY_TRANSFER_INVENTORY_SOUND();});
    }
    
  }

  // Inventory on the player's own ships docked near their island can be transferred to their island.
  // Similarly, players can transfer inventory from their island to their ships docked nearby.
  async function transferBetweenIslandAndShipsAction() {
    PLAY_BUTTON_CLICK_SOUND();
    if (!player) return toast.error("Player not found");
    if (!transferPath.some((path) => path.quantity !== 0)) return toast.error("No tranfer found!");
    
    const forwardPath: any[] = transferPath.map((path) => (path.quantity > 0 ? path.quantity : 0));
    const reversePath: any[] = transferPath.map((path) => (path.quantity < 0 ? {...path,quantity:Math.abs(path.quantity)}: {...path,quantity:0}));

    //making coordinates (0,0) to not to update the location of roster on contract side
    const rosterPosition = {
      updatedCoordinates:{
        x: 0,
        y: 0},
      updatedSailSegment: 0,
      updatedAt: Number(0)
    };  //need to update from getrosterposition but if it needed in future
    console.log({player:BigInt(player?.id),
      rosterSequenceNumber,
      col1:BigInt(col1ShipId),
      reversePath,
      rosterPosition,forwardPath, transferPath});

    toast.loading("Inventory transferring, it may take a while...");
    if (forwardPath.some((quantity) => quantity !== 0)) {
      writeWorldContract("app__rosterTakeOutShipInventory",[
        BigInt(player?.id),
        rosterSequenceNumber,
        BigInt(col1ShipId),
        forwardPath,
        rosterPosition
      ],()=>{PLAY_TRANSFER_INVENTORY_SOUND();});
    }
    if (reversePath.some((quantity) => quantity !== 0)) {
      writeWorldContract("app__rosterPutInShipInventory",[
        BigInt(player?.id),
        rosterSequenceNumber,
        BigInt(col1ShipId),
        reversePath,
        rosterPosition
      ],()=>{PLAY_TRANSFER_INVENTORY_SOUND();});
    }
  }

  return (
    <button
      className="h-12 text-white flex justify-center items-center cursor-pointer bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%] px-8 py-1"
      type="button"
      onClick={col2IslandFlag ? transferBetweenIslandAndShipsAction : transferBetweenShipsAction}
    >
      Transfer
    </button>
  );
}
