// Each player has 5 rosters, numbered 0-4.
// The roster numbered 0 is the "unassigned roster", where newly built ships are initially added.
// Rosters numbered 1-4 can each accommodate 4 ships.
// This form can help players transfer their ships from one roster to another.

import toast from "react-hot-toast";
import { useMUD } from "../../../useMUD";
import { useState, useEffect } from "react";
import { IShipHere } from "../menus/ShipsMenu";
import { truncateString } from "../../../utils/tools";
import AppModal from "../shared/AppModal";
import { PLAY_ADD_ROSTER_SOUND, PLAY_UNASSIGNED_ROSTER_SOUND } from "../../../utils/audio";
import { maxUint64 } from "viem";

export default function TransferShipForm({
  unassignedRoster,
  toRosterSequenceNumber,
  toPosition,
  actionFlag,
  removeShip,
}: {
  unassignedRoster: IShipHere[];
  toRosterSequenceNumber: number;
  toPosition: number;
  actionFlag: boolean; // true for adding, false for reomveing
  removeShip?:IShipHere;
}) {
  const {player, writeWorldContract} = useMUD();
  const [ShipForm,setShipForm] = useState<boolean>(false);
  const [selectedShips, setSelectedShips] = useState<IShipHere[]>([]);
  // const [pending,setPending] = useState(false);
  async function transferAction(assignToRoster: boolean) {
    // setPending(true);
    if(!player) return toast.error('Player not found');
    // console.log(selectedShips);
    if (actionFlag && toPosition >= 4) return toast.error("A roster can contain up to 4 ships only!");
    else if (actionFlag && selectedShips.length<1) return toast.error("No ship is selected from your Unassigned Roster!");
    // for (let i = 0;i < selectedShips.length;i++){
    //   writeWorldContract("app__rosterTransferShip",[
    //     BigInt(player.id),
    //     selectedShips[i].rosterSequenceNumber,
    //     selectedShips[i].id,
    //     BigInt(player.id),
    //     toRosterSequenceNumber,
    //     maxUint64
    //   ],({status}) => {
    //     if (status) PLAY_ADD_ROSTER_SOUND();
    //   });
    // }
    const ship_ids = selectedShips.map(ship => ship.id);
    // console.log(ship_ids);
    if(!actionFlag){
      writeWorldContract("app__rosterTransferShip",[
        BigInt(player.id),
        removeShip?.rosterSequenceNumber,
        removeShip?.id,
        BigInt(player.id),
        toRosterSequenceNumber,
        maxUint64
      ],() => {
        PLAY_UNASSIGNED_ROSTER_SOUND();
      });
    }else if(actionFlag){
      writeWorldContract("app__rosterTransferMultiShips",[
        BigInt(player.id),
        0,
        ship_ids,
        BigInt(player.id),
        toRosterSequenceNumber,
        0
      ],() => {
        PLAY_ADD_ROSTER_SOUND();
      });
    }
    // setPending(false);
    return;
  }

  const handleShipClick = (ship:IShipHere) => {
    if (selectedShips.includes(ship)) {
      // If the ship is already selected, remove it from the array
      // console.log({ship});
      setSelectedShips(selectedShips.filter(({id}) => id !== ship.id));
    } else {
      // Otherwise, add it to the selected ships array
      setSelectedShips([...selectedShips, ship]);
    }
  };
  console.log({selectedShips});

  useEffect(()=>{
    if(ShipForm && !(unassignedRoster.length)){
      setShipForm(false);
      toast.error("No ship is available from your Unassigned Roster!");
    } 
  },[ShipForm, unassignedRoster]);
  return (
    <>
      {actionFlag ? (
        <div className="w-full flex justify-center items-center">
          <div
            className="w-16 h-16 flex justify-center items-center cursor-pointer bg-frame-normal bg-center bg-no-repeat bg-[length:100%_100%]"
            onClick={() => {
              setShipForm(true);
            }}
          >
            <span className="text-5xl text-white -mt-2">+</span>
          </div>
        </div>
      ) : (
        <div className="absolute top-2 right-2.5 cursor-pointer"><img onClick={()=>{transferAction(false)}} className="w-[28px] h-[28px]" src="/frontend/icon/x-mark.png" alt="x-icon" /></div>
      )}
      {ShipForm && unassignedRoster &&(
        <AppModal frame="bg-frame-lg">
          <div className="w-full h-96 flex flex-col items-center gap-1 bg-center bg-no-repeat bg-frame-lg bg-[length:100%_100%] px-4 py-1">
            <div className="text-white">
              Select the Ships to add.
            </div>
            <div className="w-full h-auto mx-9 flex flex-col gap-2 overflow-y-auto scrollbar-hide">
              {unassignedRoster.map((ship, index)=>(
                <div className="w-full h-[70px] flex justify-between items-center bg-frame-ship bg-center bg-no-repeat bg-[length:100%_100%]" 
                  onClick={()=>handleShipClick(ship)}>
                  <div className="relative w-[13%] h-full flex justify-center items-center ml-3">
                    <div className="absolute top-1 left-3 text-white text-xl">{index + 1}</div>
                    <div className="w-1/2"><img className="w-[48px] h-[48px]" src={ship.iconUrl} alt="ship-icon" /></div>
                  </div>
                  <div className="h-full"><img className=" w-[5px] h-full" src="/frontend/item/ship-line-col.png" alt="ship-line" /></div>

                  <div className={`flex-1 flex justify-between items-cente px-6 py-1 ${selectedShips.includes(ship)?"text-red-600":"text-white"}`}>
                    <div className="flex-1 flex flex-col justify-center items-start gap-[2px] text-sm">
                      <p className=" line-clamp-1">Name: {ship.name}</p>
                      <p>ID: {truncateString(ship.id.toString())}</p>
                      <p>HP: {ship.healthPoints}</p>
                    </div>

                    <div className="flex-1 flex justify-between items-center">
                      {[
                        {
                          count: ship.attack,
                          iconUrl: "/frontend/icon/attack.png",
                        },
                        {
                          count: ship.protection,
                          iconUrl: "/frontend/icon/defense.png",
                        },
                        {
                          count: ship.speed,
                          iconUrl: "/frontend/icon/speed.png",
                        },
                      ].map((attr, index) => (
                        <div key={index} className="h-16 relative flex justify-center items-center">
                          <img className="w-[38px] h-[38px]" src={attr.iconUrl} alt="attr-icon" />
                          <p className="absolute bottom-0 -right-2 text-xl text-white">
                            {attr.count}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button className=" flex justify-center items-center text-white max-lg:text-sm cursor-pointer bg-frame-roster bg-center bg-no-repeat bg-[length:100%_100%] px-2 py-1 false"
              onClick={()=>{
                transferAction(true);
                setShipForm(false);}}>
                   Add
            </button>
          </div>
        </AppModal>
      )}
    </>
  );
}
