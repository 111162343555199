import { useState } from "react";
import { useEntityQuery } from "@latticexyz/react";
import { useMUD } from "../../../useMUD";
import {  getComponentValueStrict, HasValue, Has } from "@latticexyz/recs";
import { decodeEntity } from "@latticexyz/store-sync/recs";

import InventoryMenu from "./InventoryMenu";
import CraftMenu from "./CraftMenu";
import IslandProductMenu from "./IslandProductMenu";
import ActionButtons from "../shared/ActionButtons";
import { SkillType } from "../../../constant";
import { formatInventory, formatResource, ValueOf } from "../../../utils/tools";

export function IslandMenu() {
  const [islandProductMenuFlag, setIslandProductMenuFlag] = useState<boolean>(false);
  const [inventoryMenuFlag, setInventoryMenuFlag] = useState<boolean>(false);
  const [craftMenuFlag, setCraftMenuFlag] = useState<boolean>(false);

  const [productType, setProductType] = useState<string>("ore");
  const {
    networkLayer: {
      components: { PlayerInventory, SkillProcess }
    },
    player
  } = useMUD();

  const activeEntities = useEntityQuery([
    HasValue(SkillProcess, {completed: false}) // Get all entities with the SkillProcess component
  ]);

  const playersActiveProducts = activeEntities.map((entity) => {
    const skillProcessIds = decodeEntity({"skillProcessIdSkillType": 'uint8', "skillProcessIdPlayerId": 'uint256', "skillProcessIdSequenceNumber": 'uint8'}, entity);
    if(Number(skillProcessIds.skillProcessIdPlayerId) !== player?.id) return null;
    return {skillType: skillProcessIds.skillProcessIdSkillType.toString(), skillSequence: skillProcessIds.skillProcessIdSequenceNumber};
  })
  const activeProducts = playersActiveProducts.filter((data) => data) as {skillType: ValueOf<typeof SkillType>, skillSequence: number}[];

  const InventoryEntities = useEntityQuery([
    Has(PlayerInventory),
    // HasValue(PlayerInventory, {playerId: player?.id}),
  ]);

  const inventory = InventoryEntities.map((entity) => {
    const { playerId } = decodeEntity({"playerId": "uint256", "inventoryIndex": "uint64"}, entity);
    const { inventoryItemId, inventoryQuantity} = getComponentValueStrict(PlayerInventory, entity);
    if(Number(playerId) !== player?.id) return null;
    return {
      inventoryItemId,
      inventoryQuantity,
    }
  }).filter((data) => data);

    const { ore: oreLeft, wood: woodLeft, seed: seedsLeft } = formatResource(inventory);
    const { copper: copperLeft, tree: logLeft, cotton: cottonLeft } = formatInventory(inventory);
    // console.log({oreLeft, woodLeft, seedsLeft, copperLeft, logLeft, cottonLeft});  
  
  // Click action buttons
  function handleActionClick(flag: string) {
    // Product buttons
    if (["ore", "wood", "seed"].includes(flag)) {
      setProductType(flag);

      if (flag === productType) setIslandProductMenuFlag((prev) => !prev);
      else setIslandProductMenuFlag(true);

      setInventoryMenuFlag(false);
      setCraftMenuFlag(false);
    }

    // Inventory button
    else if (flag === "inventory") {
      setInventoryMenuFlag((prev) => !prev);
      setIslandProductMenuFlag(false);
      setCraftMenuFlag(false);
    }

    // Craft button
    else if (flag === "craft") {
      setCraftMenuFlag((prev) => !prev);
      setIslandProductMenuFlag(false);
      setInventoryMenuFlag(false);
    }
  }

  return (
    <>
      {islandProductMenuFlag && (
        <IslandProductMenu productType={productType} oreLeft={oreLeft} woodLeft={woodLeft} seedsLeft={seedsLeft} activeProducts={activeProducts} />
      )}

      {inventoryMenuFlag && <InventoryMenu copperLeft={copperLeft} logLeft={logLeft} cottonLeft={cottonLeft} />}

      {craftMenuFlag && <CraftMenu copperLeft={copperLeft} logLeft={logLeft} cottonLeft={cottonLeft} activeProducts={activeProducts} />}

      <ActionButtons handleActionClick={handleActionClick} />
    </>
  );
}
