import { useEffect, useState } from "react";
import { useQuery } from "urql";

import AppLoading from "../shared/AppLoading";
import { useMUD } from "../../../useMUD";

// import { getLeaderboard } from "@/actions/quest.action";
// import { getNftByOwner } from "@/actions/player.action";

const EVENT_QUERY = `
  query Player($id: ID!) {
    players {
      totalAcumuletedPoint
      dailyQuest(where: { id_contains: "Energy" }) {
        quantity
      }
      name
      id
    }
  }`;

export default function Leaderboard({
  handleCloseMenu,
}: {
  handleCloseMenu: () => void;
}) {
  const [rankList, setRankList] = useState<any[]>([]);
  const [currentPlayerRank, setCurrentPlayerRank] = useState<any>();
  const [pending, setPending] = useState<boolean>(false);
  const { player } = useMUD();

  const [result] = useQuery({
    query: EVENT_QUERY,
    variables: { id: player?.id },
  });
  const { data, fetching, error } = result;
  console.log({ data, fetching, error });

  async function CalculateTotalPoints(players: any[]) {
    const playerNameWithPoints = players.map((player) => {
      const totalPoints =
        player.totalAcumuletedPoint + player.dailyQuest[0].quantity * 3;
      return {
        PlayerName: player.name,
        TotalPoints: totalPoints,
        id: player.id,
      };
    });
    return playerNameWithPoints;
  }

  useEffect(() => {
    async function initRankList() {
      setPending(true);
      if (!data) return;
      // For other players
      const pairs = await CalculateTotalPoints(data.players);
      const sortedPairs = [...pairs].sort(
        (a, b) => b.TotalPoints - a.TotalPoints
      );
      const rankings = sortedPairs.map(({ PlayerName, TotalPoints }) => {
        return { PlayerName, points: TotalPoints };
      });
      setRankList(rankings);

      // For the current player
      if (!player) return;
      const cpRank = sortedPairs.findIndex(({ id }) => id === player?.id) + 1;
      const cpPoints = sortedPairs[cpRank - 1].TotalPoints;
      setCurrentPlayerRank({
        cpRank,
        cpPoints: cpPoints ? cpPoints : 0,
      });

      setPending(false);
    }

    initRankList();
  }, [player, data]);

  return (
    <div className="relative w-[240px] bg-frame-prod bg-center bg-no-repeat bg-[length:100%_100%] text-white z-30">
      <img
        className="absolute top-4 right-3 cursor-pointer z-40"
        src="/frontend/icon/x-mark.png"
        alt="x-icon"
        width={24}
        height={24}
        onClick={handleCloseMenu}
      />

      <div className="bg-frame-btn bg-center bg-no-repeat bg-[length:100%_100%]">
        <h1 className="text-center text-2xl py-3">Leaderboard</h1>
      </div>

      <div className="flex justify-between items-center border-b-[1px] border-b-yellow-600 px-6 py-1">
        <p>Ranking</p>
        <p>Player</p>
        <p>Points</p>
      </div>

      <div className="flex flex-col items-center min-h-[240px] max-h-[260px] overflow-y-auto scrollbar-hidden font-medium">
        {!fetching ? (
          rankList &&
          rankList.map((rank, index) => (
            <div
              key={index}
              className="w-full flex justify-between items-center border-b-[1px] border-b-yellow-600 px-9 py-1.5"
            >
              <p>{index + 1}</p>
              <p>{rank.PlayerName}</p>
              <p>{rank.points}</p>
            </div>
          ))
        ) : (
          <AppLoading size={80} />
        )}
      </div>

      {/* Rank and points of the current player */}
      {currentPlayerRank && !pending && (
        <div className="flex justify-between items-center border-t-[1px] border-t-yellow-600 text-yellow-400 font-semibold px-9 pt-2 pb-6 mx-1 z-40">
          <p>{currentPlayerRank?.cpRank}</p>
          <p>You</p>
          <p>{currentPlayerRank?.cpPoints}</p>
        </div>
      )}
    </div>
  );
}
