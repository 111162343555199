import { useEffect, useState } from "react";

import { ContactButtons } from "./ui/shared/ContactButtons";
import AppLoading from "./ui/shared/AppLoading";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useQuery } from "urql";

import { truncateString } from "../utils/tools";
// import { useMUD } from "../useMUD";
import { useStore } from "../useStore";
import EnergyOnboardingForm from "./ui/form/EnergyOnboardingForm";

const EVENT_QUERY = `
  query Player {
    players {
      totalAcumuletedPoint
      dailyQuest(where: { id_contains: "Energy" }) {
        quantity
      }
      name
      id
    }
  }`;

export default function Faucet() {
  const [rankList, setRankList] = useState<any[]>([]);
  const [leaderboardpending, setLeaderboardPending] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [HeightclassName, setHeightClassName] = useState("h-full");
  const [MarginclassName, setMarginClassName] = useState("mt-0");
  const { networkLayer, externalWalletClient } = useStore((state) => state);
  // const [currentAccountAddress,setCurrentAccountAddress] = useState(externalWalletClient.account.address);

  const [result] = useQuery({
    query: EVENT_QUERY,
  });
  const { data, fetching, error } = result;
  console.log({ data, fetching, error });

  useEffect(() => {
    // Function to update className based on window height
    const updateClassName = () => {
      if (window.innerHeight >= 1000) {
        setHeightClassName("h-screen");
        setMarginClassName("mt-0");
      } else {
        setHeightClassName("h-full");
        setMarginClassName("lg:mt-[9rem]");
      }
    };
    updateClassName();
    window.addEventListener("resize", updateClassName);
    return () => {
      window.removeEventListener("resize", updateClassName);
    };
  }, []);

  useEffect(() => {
    (async () => {
      setLeaderboardPending(true);
      await prepareLeaderboard();
      setLeaderboardPending(false);

      setInterval(async () => {
        await prepareLeaderboard();
      }, 15000);
    })();
  }, [data]);

  async function CalculateTotalPoints(players: any[]) {
    const playerNameWithPoints = players.map((player) => {
      const totalPoints =
        player.totalAcumuletedPoint + player.dailyQuest[0].quantity * 3;
      return {
        PlayerName: player.name,
        TotalPoints: totalPoints,
        id: player.id,
      };
    });
    return playerNameWithPoints;
  }

  async function prepareLeaderboard() {
    if (!data) return;
    const pairs = await CalculateTotalPoints(data.players);
    const sortedPairs = [...pairs].sort(
      (a, b) => b.TotalPoints - a.TotalPoints
    );
    const rankings = sortedPairs.map(({ PlayerName, TotalPoints }) => {
      return { PlayerName, points: TotalPoints };
    });
    setRankList(rankings);
  }

  // When you are on story testnet, uncomment this
  // async function claimIP() {
  // if (!externalWalletClient.account.address)
  //     return toast.error("Please connect your wallet first!");
  //   toast.loading("Claiming SUI coin...");

  //   try {
  //     await requestSuiFromFaucetV0({
  //       host: getFaucetHost("testnet"),
  //       recipient: currentAccountAddress,
  //     });

  //     toast.success("1 SUI coin claimed!");
  //   } catch (error: any) {
  //     toast.error("Failed to claim SUI coin! Please come back later.");
  //   }
  // }
  // async function disconnect() {
  //    externalWalletClient?.account.
  // }

  return (
    <div
      className={` ${HeightclassName} relative flex justify-center items-center bg-[#060615] max-lg:px-16 max-lg:py-24`}
    >
      <div className="flex flex-col justify-center items-center">
        <div className=" flex flex-col justify-center items-center">
          <ContactButtons />
          <div className="sm:absolute max-sm:mt-6 max-sm:top-20 sm:top-6 left-6 flex items-center gap-3">
            <a
              href="/"
              className="w-36 h-12 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex justify-center items-center"
            >
              <span className="text-white text-lg leading-none mt-1">
                GO BACK
              </span>
            </a>
            <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                  ready &&
                  account &&
                  chain &&
                  (!authenticationStatus ||
                    authenticationStatus === "authenticated");
                return (
                  <div
                    {...(!ready && {
                      "aria-hidden": true,
                      style: {
                        opacity: 0,
                        pointerEvents: "none",
                        userSelect: "none",
                      },
                    })}
                    className="text-white cursor-pointer text-3xl leading-none mt-1"
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <button
                            onClick={openConnectModal}
                            type="button"
                            className="w-36 h-12 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex justify-center items-center"
                          >
                            <span className="text-white text-lg leading-none mt-1">
                              CONNECT
                            </span>
                          </button>
                        );
                      }
                      if (chain.unsupported) {
                        return (
                          <button
                            onClick={openChainModal}
                            type="button"
                            className="w-36 h-12 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex justify-center items-center"
                          >
                            <span className="text-white text-lg leading-none mt-1">
                              Wrong network
                            </span>
                          </button>
                        );
                      }
                      return (
                        <div className="flex flex-col items-center gap-4">
                          <div style={{ display: "flex", gap: 12 }}>
                            <button
                              onClick={openChainModal}
                              style={{ display: "flex", alignItems: "center" }}
                              type="button"
                              className="w-36 h-12 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex justify-center items-center"
                            >
                              {chain.hasIcon && (
                                <div
                                  style={{
                                    background: chain.iconBackground,
                                    width: 12,
                                    height: 12,
                                    borderRadius: 999,
                                    overflow: "hidden",
                                    marginRight: 4,
                                  }}
                                >
                                  {chain.iconUrl && (
                                    <img
                                      alt={chain.name ?? "Chain icon"}
                                      src={chain.iconUrl}
                                      style={{ width: 12, height: 12 }}
                                    />
                                  )}
                                </div>
                              )}
                              <span className="text-white text-lg leading-none mt-1">
                                {chain.name}
                              </span>
                            </button>
                            <button
                              onClick={openAccountModal}
                              type="button"
                              className="w-48 h-12 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex justify-center items-center"
                            >
                              <span className="text-white text-lg leading-none mt-1">
                                {account.displayName}
                                {account.displayBalance
                                  ? ` (${account.displayBalance})`
                                  : ""}
                              </span>
                            </button>
                          </div>
                        </div>
                      );
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom>
          </div>
        </div>
        <div
          className={`lg:w-[942px] ${MarginclassName} max-sm:mt-10 flex max-lg:flex-col text-white lg:gap-4 max-lg:gap-12 mb-16`}
        >
          <div className="basis-3/5 flex flex-col gap-4">
            {/* Faucets */}
            <div className="flex flex-col items-center bg-frame-prod bg-center bg-no-repeat bg-[length:100%_100%] px-12 py-8 gap-4">
              <div className="w-full flex flex-col items-center">
                <div className="w-full flex justify-between items-center">
                  <img
                    src="/frontend/onboarding/skeleton-left.png"
                    alt="bone"
                    width={100}
                    height={25}
                  />
                  <h2 className="md:text-3xl text-center font-bold">
                    Infinite Seas Faucet
                  </h2>
                  <img
                    src="/frontend/onboarding/skeleton-right.png"
                    alt="bone"
                    width={100}
                    height={25}
                  />
                </div>

                <img
                  className="w-full"
                  src="/frontend/onboarding/stick.png"
                  alt="stick"
                  width={100}
                  height={25}
                />

                <p className="text-sm text-[#FFCD92]">
                  Only for Infinite Sea Batch 0 NFT holders
                </p>
              </div>

              <div className="w-full rounded-lg bg-[#3C3C3C] px-4 py-2">
                <input
                  className="w-full bg-transparent placeholder:text-[#777777] outline-none py-1"
                  type="text"
                  placeholder="Please connect your wallet first!"
                  defaultValue={externalWalletClient?.account.address}
                  disabled
                />
              </div>

              <div className="flex flex-col items-center font-bold gap-3">
                <button
                  className="w-64 h-16 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex justify-center items-center gap-3"
                  type="button"
                  onClick={() => {}}
                >
                  <img
                    src="/frontend/onboarding/ip.png"
                    alt="sui-logo"
                    width={32}
                    height={32}
                  />
                  <span className="text-xl leading-none mt-1">CLAIM IP</span>
                </button>

                {networkLayer && externalWalletClient && (
                  <EnergyOnboardingForm />
                )}
              </div>
            </div>

            {/* Top players */}
            <div className="max-lg:hidden relative flex flex-col items-center bg-frame-prod bg-center bg-no-repeat bg-[length:100%_100%] p-8 gap-4">
              <img
                className="absolute -top-8 -left-16"
                src="/frontend/onboarding/face.png"
                alt="face-img"
                width={128}
                height={128}
              />

              <h2 className="text-3xl font-bold">TOP PLAYERS</h2>

              <div className="w-full h-full flex justify-between gap-2">
                <div className="basis-3/5 flex flex-col justify-between items-center bg-[#FFB800] rounded-xl p-4">
                  <div className="w-full flex justify-between items-center">
                    <h1 className="text-5xl font-bold">#1</h1>
                    {rankList && rankList.length > 0 && (
                      <div className="flex items-center gap-1">
                        <img
                          className="rounded-full"
                          src={
                            rankList[0].avatarUrl
                              ? rankList[0].avatarUrl
                              : "/frontend/icon/avatar.png"
                          }
                          alt="avatar-img"
                          width={56}
                          height={56}
                        />
                        {rankList[0].PlayerName ? (
                          <h2 className="text-xl font-bold">
                            {rankList[0].PlayerName}
                          </h2>
                        ) : (
                          <h2 className="text-xl font-bold">No PlayerName</h2>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="flex items-center text-3xl font-bold gap-2">
                    <h2>POINTS: </h2>
                    {rankList && rankList.length > 0 && (
                      <h2>{rankList[0].points}</h2>
                    )}
                  </div>
                </div>

                <div className="basis-2/5 flex flex-col gap-2">
                  <div className="flex-1 flex flex-col justify-between items-center bg-[#535353] rounded-xl px-4 py-2">
                    <div className="w-full flex flex-col justify-between items-center gap-3">
                      <div className="w-full flex justify-between items-center">
                        <h3 className="text-xl font-bold">#2</h3>
                        {rankList && rankList.length > 1 && (
                          <div className="flex items-center gap-1">
                            <img
                              className="rounded-full"
                              src={
                                rankList[1].avatarUrl
                                  ? rankList[1].avatarUrl
                                  : "/frontend/icon/avatar.png"
                              }
                              alt="avatar-img"
                              width={36}
                              height={36}
                            />
                            {rankList[1].PlayerName ? (
                              <h3 className="font-bold">
                                {rankList[1].PlayerName}
                              </h3>
                            ) : (
                              <h3 className="font-bold">No player Name</h3>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="flex items-center text-xl gap-1.5">
                        <h3 className="text-lg">POINTS: </h3>
                        {rankList && rankList.length > 1 && (
                          <h3>{rankList[1].points}</h3>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex-1 flex flex-col justify-between items-center bg-[#535353] rounded-xl px-4 py-2">
                    <div className="w-full flex flex-col justify-between items-center gap-3">
                      <div className="w-full flex justify-between items-center">
                        <h3 className="text-xl font-bold">#3</h3>
                        {rankList && rankList.length > 2 && (
                          <div className="flex items-center gap-1">
                            <img
                              className="rounded-full"
                              src={
                                rankList[2].avatarUrl
                                  ? rankList[2].avatarUrl
                                  : "/frontend/icon/avatar.png"
                              }
                              alt="avatar-img"
                              width={36}
                              height={36}
                            />
                            {rankList[2].PlayerName ? (
                              <h3 className="font-bold">
                                {rankList[2].PlayerName}
                              </h3>
                            ) : (
                              <h3 className="font-bold">No player name</h3>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="flex items-center text-xl gap-1.5">
                        <h3 className="text-lg">POINTS: </h3>
                        {rankList && rankList.length > 1 && (
                          <h3>{rankList[2].points}</h3>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Leaderboard */}
          <div className="relative basis-2/5 bg-frame-prod bg-center bg-no-repeat bg-[length:100%_100%] text-white">
            <img
              className="absolute -top-9 -right-12 rotate-[27deg]"
              src="/frontend/onboarding/cron.png"
              alt="cron-img"
              width={128}
              height={128}
            />

            <div className="bg-frame-prod bg-center bg-no-repeat bg-[length:100%_100%]">
              <h1 className="text-center text-3xl py-4">Leaderboard</h1>
            </div>

            <div className="flex justify-between items-center border-b-[1px] border-b-yellow-600 text-lg px-8 py-3">
              <p>Ranking</p>
              <p>Player/NFT/Address</p>
              <p>Points</p>
            </div>

            <div className="h-[560px] flex flex-col items-center text-lg font-medium overflow-y-auto scrollbar-hidden">
              {!leaderboardpending ? (
                rankList.map((rank, index) => (
                  <div
                    key={index}
                    className="w-full flex justify-between items-center border-b-[1px] border-b-yellow-600 px-12 py-3"
                  >
                    <p>{index + 1}</p>
                    {rank.PlayerName ? (
                      <p>{rank.PlayerName}</p>
                    ) : (
                      <p>No Player Name</p>
                    )}
                    <p>{rank.points}</p>
                  </div>
                ))
              ) : (
                <AppLoading size={150} />
              )}
            </div>

            <div className="h-[20px]" />
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div
  //     className={` ${HeightclassName} relative flex justify-center items-center bg-[#060615] max-lg:px-16 max-lg:py-24`}
  //   >
  //     <div className="flex flex-col justify-center items-center">
  //       <div className=" flex flex-col justify-center items-center">
  //         <ContactButtons />
  //         <div className="sm:absolute max-sm:mt-6 max-sm:top-20 sm:top-6 left-6 flex items-center gap-3">
  //           <a
  //             href="/"
  //             className="w-36 h-12 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex justify-center items-center"
  //           >
  //             <span className="text-white text-lg leading-none mt-1">
  //               GO BACK
  //             </span>
  //           </a>

  //           {!externalWalletClient?.account ? (
  //             <button
  //               className="w-36 h-12 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex justify-center items-center"
  //               type="button"
  //               onClick={() => {
  //                 setOpen(true);
  //               }}
  //             >
  //               <span className="text-white text-lg leading-none mt-1">
  //                 CONNECT
  //               </span>
  //             </button>
  //           ) : (
  //             <></>
  //             // <button
  //             //   className="w-44 h-12 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex justify-center items-center"
  //             //   type="button"
  //             //   onClick={() => {
  //             //     disconnect();
  //             //     setOpen(false);
  //             //   }}
  //             // >
  //             //   <span className="text-white text-lg leading-none mt-1">
  //             //     DISCONNECT
  //             //   </span>
  //             // </button>
  //           )}
  //         </div>
  //       </div>
  //       <div
  //         className={`lg:w-[942px] ${MarginclassName} max-sm:mt-10 flex max-lg:flex-col text-white lg:gap-4 max-lg:gap-12`}
  //       >
  //         <div className="basis-3/5 flex flex-col gap-4">
  //           {/* Faucets */}
  //           <div className="flex flex-col items-center bg-frame-prod bg-center bg-no-repeat bg-[length:100%_100%] px-12 py-8 gap-4">
  //             <div className="w-full flex flex-col items-center">
  //               <div className="w-full flex justify-between items-center">
  //                 <img
  //                   src="/frontend/onboarding/skeleton-left.png"
  //                   alt="bone"
  //                   width={100}
  //                   height={25}
  //                 />
  //                 <h2 className="md:text-3xl text-center font-bold">
  //                   Infinite Seas Faucet
  //                 </h2>
  //                 <img
  //                   src="/frontend/onboarding/skeleton-right.png"
  //                   alt="bone"
  //                   width={100}
  //                   height={25}
  //                 />
  //               </div>

  //               <img
  //                 className="w-full"
  //                 src="/frontend/onboarding/stick.png"
  //                 alt="stick"
  //                 width={100}
  //                 height={25}
  //               />

  //               <p className="text-sm text-[#FFCD92]">
  //                 Only for Infinite Sea Batch 0 NFT holders
  //               </p>
  //             </div>

  //             <div className="w-full rounded-lg bg-[#3C3C3C] px-4 py-2">
  //               <input
  //                 className="w-full bg-transparent placeholder:text-[#777777] outline-none py-1"
  //                 type="text"
  //                 placeholder="Please connect your wallet first!"
  //                 defaultValue={externalWalletClient?.account.address}
  //                 disabled
  //               />
  //             </div>

  //             <div className="flex flex-col items-center font-bold gap-3">
  //               {/* When you are on sui testnet, uncomment this */}
  //               {/* {CURRENT_ENV === "SUI" ? (
  //                 <button
  //                   className="w-64 h-16 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex justify-center items-center gap-3"
  //                   type="button"
  //                   onClick={claimSui}
  //                 >
  //                   <Image
  //                     src="/frontend/onboarding/sui.png"
  //                     alt="sui-logo"
  //                     width={32}
  //                     height={32}
  //                     priority
  //                   />
  //                   <span className="text-xl leading-none mt-1">CLAIM SUI</span>
  //                 </button>
  //               ) : (
  //                 <Link
  //                   href="https://faucet.movementlabs.xyz/?network=sui"
  //                   target="_blank"
  //                   className="w-64 h-16 bg-frame-login bg-center bg-no-repeat bg-[length:100%_100%] flex justify-center items-center gap-3"
  //                 >
  //                   <Image
  //                     className="rounded-full"
  //                     src="/frontend/onboarding/mvmt.svg"
  //                     alt="mvmt-logo"
  //                     width={36}
  //                     height={36}
  //                     priority
  //                   />
  //                   <span className="text-xl leading-none mt-1">
  //                     CLAIM MOVE
  //                   </span>
  //                 </Link>
  //               )} */}

  //               <EnergyOnboardingForm />
  //             </div>
  //           </div>

  //           {/* Top players */}
  //           <div className="max-lg:hidden relative flex flex-col items-center bg-frame-prod bg-center bg-no-repeat bg-[length:100%_100%] p-8 gap-4">
  //             {/* <img
  //               className="absolute -top-8 -left-16"
  //               src="/frontend/onboarding/face.png"
  //               alt="face-img"
  //               width={128}
  //               height={128}
  //             />

  //             <h2 className="text-3xl font-bold">TOP PLAYERS</h2>

  //             <div className="w-full h-full flex justify-between gap-2">
  //               <div className="basis-3/5 flex flex-col justify-between items-center bg-[#FFB800] rounded-xl p-4">
  //                 <div className="w-full flex justify-between items-center">
  //                   <h1 className="text-5xl font-bold">#1</h1>
  //                   {rankList && rankList.length > 0 && (
  //                     <div className="flex items-center gap-1">
  //                       <img
  //                         className="rounded-full"
  //                         src={
  //                           rankList[0].avatarUrl
  //                             ? rankList[0].avatarUrl
  //                             : "/frontend/icon/avatar.png"
  //                         }
  //                         alt="avatar-img"
  //                         width={56}
  //                         height={56}
  //                       />
  //                       {rankList[0].playerName ? (
  //                         <h2 className="text-xl font-bold">
  //                           {rankList[0].playerName}
  //                         </h2>
  //                       ) : (
  //                         <h2 className="text-xl font-bold">
  //                           {truncateString(
  //                             rankList[0].nftId
  //                               ? rankList[0].nftId
  //                               : rankList[0].wallet
  //                           )}
  //                         </h2>
  //                       )}
  //                     </div>
  //                   )}
  //                 </div>

  //                 <div className="flex items-center text-3xl font-bold gap-2">
  //                   <h2>POINTS: </h2>
  //                   {rankList && rankList.length > 0 && (
  //                     <h2>{rankList[0].points}</h2>
  //                   )}
  //                 </div>
  //               </div>

  //               <div className="basis-2/5 flex flex-col gap-2">
  //                 <div className="flex-1 flex flex-col justify-between items-center bg-[#535353] rounded-xl px-4 py-2">
  //                   <div className="w-full flex flex-col justify-between items-center gap-3">
  //                     <div className="w-full flex justify-between items-center">
  //                       <h3 className="text-xl font-bold">#2</h3>
  //                       {rankList && rankList.length > 1 && (
  //                         <div className="flex items-center gap-1">
  //                           <Image
  //                             className="rounded-full"
  //                             src={
  //                               rankList[1].avatarUrl
  //                                 ? rankList[1].avatarUrl
  //                                 : "/frontend/icon/avatar.png"
  //                             }
  //                             alt="avatar-img"
  //                             width={36}
  //                             height={36}
  //                             priority
  //                           />
  //                           {rankList[1].playerName ? (
  //                             <h3 className="font-bold">
  //                               {rankList[1].playerName}
  //                             </h3>
  //                           ) : (
  //                             <h3 className="font-bold">
  //                               {truncateString(
  //                                 rankList[1].nftId
  //                                   ? rankList[1].nftId
  //                                   : rankList[1].wallet
  //                               )}
  //                             </h3>
  //                           )}
  //                         </div>
  //                       )}
  //                     </div>

  //                     <div className="flex items-center text-xl gap-1.5">
  //                       <h3 className="text-lg">POINTS: </h3>
  //                       {rankList && rankList.length > 1 && (
  //                         <h3>{rankList[1].points}</h3>
  //                       )}
  //                     </div>
  //                   </div>
  //                 </div>

  //                 <div className="flex-1 flex flex-col justify-between items-center bg-[#535353] rounded-xl px-4 py-2">
  //                   <div className="w-full flex flex-col justify-between items-center gap-3">
  //                     <div className="w-full flex justify-between items-center">
  //                       <h3 className="text-xl font-bold">#3</h3>
  //                       {rankList && rankList.length > 2 && (
  //                         <div className="flex items-center gap-1">
  //                           <Image
  //                             className="rounded-full"
  //                             src={
  //                               rankList[2].avatarUrl
  //                                 ? rankList[2].avatarUrl
  //                                 : "/frontend/icon/avatar.png"
  //                             }
  //                             alt="avatar-img"
  //                             width={36}
  //                             height={36}
  //                             priority
  //                           />
  //                           {rankList[2].playerName ? (
  //                             <h3 className="font-bold">
  //                               {rankList[2].playerName}
  //                             </h3>
  //                           ) : (
  //                             <h3 className="font-bold">
  //                               {truncateString(
  //                                 rankList[2].nftId
  //                                   ? rankList[2].nftId
  //                                   : rankList[2].wallet
  //                               )}
  //                             </h3>
  //                           )}
  //                         </div>
  //                       )}
  //                     </div>

  //                     <div className="flex items-center text-xl gap-1.5">
  //                       <h3 className="text-lg">POINTS: </h3>
  //                       {rankList && rankList.length > 1 && (
  //                         <h3>{rankList[2].points}</h3>
  //                       )}
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>*/}
  //           </div>
  //         </div>

  //         {/* Leaderboard */}
  //         <div className="relative basis-2/5 bg-frame-prod bg-center bg-no-repeat bg-[length:100%_100%] text-white">
  //           {/* <Image
  //             className="absolute -top-9 -right-12 rotate-[27deg]"
  //             src="/frontend/onboarding/cron.png"
  //             alt="cron-img"
  //             width={128}
  //             height={128}
  //             priority
  //           />

  //           <div className="bg-frame-prod bg-center bg-no-repeat bg-[length:100%_100%]">
  //             <h1 className="text-center text-3xl py-4">Leaderboard</h1>
  //           </div>

  //           <div className="flex justify-between items-center border-b-[1px] border-b-yellow-600 text-lg px-8 py-3">
  //             <p>Ranking</p>
  //             <p>Player/NFT/Address</p>
  //             <p>Points</p>
  //           </div>

  //           <div className="h-[560px] flex flex-col items-center text-lg font-medium overflow-y-auto scrollbar-hidden">
  //             {!leaderboardpending ? (
  //               rankList.map((rank, index) => (
  //                 <div
  //                   key={index}
  //                   className="w-full flex justify-between items-center border-b-[1px] border-b-yellow-600 px-12 py-3"
  //                 >
  //                   <p>{index + 1}</p>
  //                   {rank.playerName ? (
  //                     <p>{rank.playerName}</p>
  //                   ) : (
  //                     <p>
  //                       {truncateString(rank.nftId ? rank.nftId : rank.wallet)}
  //                     </p>
  //                   )}
  //                   <p>{rank.points}</p>
  //                 </div>
  //               ))
  //             ) : (
  //               <AppLoading size={150} />
  //             )}
  //           </div>

  //           <div className="h-[20px]" /> */}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
}
