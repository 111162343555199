import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  PLAY_BUTTON_CLICK_SOUND,
  PLAY_CLAIM_ISLAND_HARVEST_SOUND,
} from "../../../utils/audio";
import { useMUD } from "../../../useMUD";
import { SkillType } from "../../../constant";

export default function HarvestProductForm({
  sequenceNumber,
  skillType,
  processCompleted,
  initialCount,
  startedAt,
}: {
  sequenceNumber: number;
  skillType: number;
  processCompleted: boolean;
  initialCount: number;
  startedAt: string;
}) {
  const [count, setCount] = useState<number>(initialCount);
  const {
    player,
    writeWorldContract,
  } = useMUD();
  // console.log({sequenceNumber});
  
  async function harvestProductAction() {
    if (!player) return toast.error("Please login first!");
    // try {
      // If the player harvests cotton seeds
      if (skillType === SkillType.FARMING) {
        if (processCompleted)
          return toast.error(
            "No cotton to harvest, please start seeding cotton first!"
          );
          writeWorldContract("app__skillProcessCompleteProduction", [SkillType.FARMING, BigInt(player.id), sequenceNumber], ({status}) => status && PLAY_CLAIM_ISLAND_HARVEST_SOUND());
      }

      // If the player harvests wood
      else if (skillType === SkillType.WOODCUTTING) {
        if (processCompleted)
          return toast.error(
            "No wood to harvest, please start wood cutting first!"
          );
          writeWorldContract("app__skillProcessCompleteCreation", [SkillType.WOODCUTTING, BigInt(player.id), sequenceNumber], ({status}) => status && PLAY_CLAIM_ISLAND_HARVEST_SOUND());
      }

      // If the player harvests ore
      else if (skillType === SkillType.MINING) {
        if (processCompleted)
          return toast.error("No ore to harvest, please start mining first!");
        writeWorldContract("app__skillProcessCompleteCreation", [SkillType.MINING, BigInt(player.id), sequenceNumber], ({status}) => status && PLAY_CLAIM_ISLAND_HARVEST_SOUND());
      }

      // If the player harvests craft
      else if (skillType === 6) {
        if (processCompleted)
          return toast.error(
            "No ship to harvest, please start crafting first!"
          );
          writeWorldContract("app__skillProcessCompleteShipProduction", [SkillType.CRAFTING, BigInt(player.id), sequenceNumber], ({status}) => status && PLAY_CLAIM_ISLAND_HARVEST_SOUND());
      }
  }

  useEffect(() => {
    setCount(initialCount);

    const timer = setInterval(() => {
      setCount((prev) => {
        if (prev <= 0) return 0;
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [initialCount, startedAt]);

  return (
    <>
      <span className="text-white">{count}s</span>

      <button
        className="text-xs border-[1px] border-white text-white disabled:border-zinc-400 disabled:text-zinc-400 rounded-sm cursor-pointer px-1"
        type="button"
        disabled={processCompleted || count > 0}
        onClick={() => {
          PLAY_BUTTON_CLICK_SOUND();
          harvestProductAction();
        }}
      >
        GET
      </button>
    </>
  );
}
