import { resourceToHex } from "@latticexyz/common";

// Game Configuration
export const ITEM_CODE_LIST = [301, 200, 102];
export const ROSTER_STATUS = { STOP: 255, SAILING: 1, BATTLING: 2, DESTROYED: 3 };
export const COMBAT_STATUS = { BATTLING: 0, ENDED: 1, CLEANED: 2 };

export const SkillType = {
  FARMING: 0,
  WOODCUTTING: 1,
  FISHING: 2,
  MINING: 3,
  SMITHING: 4,
  COOKING: 5,
  CRAFTING: 6,
  TOWNSHIP: 7,
  SAILING: 8,
} as const;

export const ITEM_IDS = {
  WOOD: 200,
  ORE: 301,
  COTTON: 102,
} as const;

export type ValueOf<T> = T[keyof T];

type AbortMap = {
  [key: string]: { [code: string]: string };
};

export const ABORT_MAP: AbortMap = {
  IAggregatorServiceSystem: {
    "119d7297": "InsufficientEnergy",
    a7d6d846: "InvalidEnergyAmount",
    "73306803": "InvalidTokenAddress",
  },
  AggregatorServiceSystem: {
    "9996b315": "AddressEmptyCode",
    cd786059: "AddressInsufficientBalance",
    bcda426e: "EmptyRosterShipIds",
    "1425ea42": "FailedInnerCall",
    "119d7297": "InsufficientEnergy",
    a7d6d846: "InvalidEnergyAmount",
    "73306803": "InvalidTokenAddress",
    "2057ac70": "RosterIsUnassignedShips",
    "5274afe7": "SafeERC20FailedOperation",
    "23230fa3": "Slice_OutOfBounds",
  },
  app__experienceTableAddLevel: {
    "19d5f34c": "ELevelNotEqualToIndex",
  },
  app__mapAddIsland: {
    ec33d34f: "ELocationAlreadyExists",
  },
  app__mapClaimIsland: {
    c4ce16fd: "EIslandAlreadyClaimed",
    "48cc66e4": "ELocationNotFound",
    "58c1a196": "ELocationTypeMismatch",
  },
  app__mapGatherIslandResources: {
    d837d76d: "IslandNotOccupied",
    aebb5d4c: "PlayerIsNotIslandOwner",
    "603a671d": "ResourceNotRegeneratedYet",
  },
  app__playerAirdrop: {
    "396d95ca": "ItemIdZero",
    c35f1bb3: "PlayerIdZero",
    f9495179: "QuantityZeroOrNegative",
  },
  app__playerClaimIsland: {
    "71d1a879": "EInvalidClaimIslandSetting",
    b9f64ad4: "EInvalidCoordinates",
    abd97c56: "ELocationNotFound",
    "73cdf3b4": "EPlayerAlreadyClaimedIsland",
    "59df57c9": "EResourceArraysLengthMismatch",
    "9e2903d3": "ESenderHasNoPermission",
  },
  app__playerGatherIslandResources: {
    abd97c56: "ELocationNotFound",
    ad1a045a: "PlayerNotIslandOwner",
    d5e9ccd7: "SenderNotPlayerOwner",
    "603a671d": "ResourceNotRegeneratedYet",
  },
  app__playerIncreaseExperienceAndItems: {
    "0e2980e6": "NewLevelLowerThanCurrent",
    c35f1bb3: "PlayerIdZero",
  },
  app__rosterAdjustShipsPosition: {
    cdad7aa2: "ShipIdNotFound",
    "8c993332": "ShipIdsAndPositionsLengthMismatch",
  },
  app__rosterCreateEnvironmentRoster: {
    "7a11911d": "InvalidShipBaseResourceQuantity",
    "5af11109": "InvalidShipResourceQuantity",
  },
  app__rosterPutInShipInventory: {
    "17866d92": "PlayerNotRosterOwner",
    "2057ac70": "RosterIsUnassignedShips",
    bd6d3cb6: "RosterNotCloseEnoughToTransfer",
    c5ca87b1: "SenderNotPlayerOwner",
    "2bcf06c8": "ShipNotInRoster",
  },
  app__rosterSetSail: {
    "624f6ba7": "IllegalSailDuration",
    d6724682: "InvalidUpdatedCoordinates",
    d1ace937: "NotEnoughEnergy",
    "93b8004e": "RosterUnfitToSail",
  },
  app__rosterTakeOutShipInventory: {
    "17866d92": "PlayerNotRosterOwner",
    "2057ac70": "RosterIsUnassignedShips",
    bd6d3cb6: "RosterNotCloseEnoughToTransfer",
    c5ca87b1: "SenderNotPlayerOwner",
    "2bcf06c8": "ShipNotInRoster",
  },
  app__rosterTransferShipInventory: {
    "17866d92": "PlayerNotRosterOwner",
    c5ca87b1: "SenderNotPlayerOwner",
    "2bcf06c8": "ShipNotInRoster",
  },
  app__rosterTransferShip: {
    e9a0b3de: "EmptyShipIdsInSourceRoster",
    "366f564b": "RosterInBattle",
    b623bcc8: "RosterNotExists",
    "6737926c": "RostersTooFarAway",
    "2bcf06c8": "ShipNotInRoster",
    "5a786633": "ToRosterInBattle",
  },
  app__energyDropRequest:{
    "43a12d09":"EnergyNotCoolYet"
  },
  app__rosterUpdateLocation: {
    "7bee88d2": "EarlyUpdate",
    d99bb7a8: "InvalidPositionUpdate",
    "7cf0c41a": "InvalidPositionUpdateAfterSailDuration",
    cbe4ed5b: "InvalidSailSegment",
    fbcfc864: "InvalidUpdateTime",
    "86950d5b": "RosterNotUnderway",
  },
  app__shipBattleInitiateBattle: {
    dda2d12f: "InitiatorNotBattleReady",
    "10d60278": "NotCloseEnoughToBattle",
    "53bd43d9": "PlayerDoesNotExist",
    "651248f6": "ResponderNotBattleReady",
  },
  app__shipBattleMakeMove: {
    eb50d644: "AttackerShipNotSet",
    "4ee960cc": "BattleNotOngoing",
    "31819462": "BothShipsDestroyed",
    "7e93e6da": "DefenderShipNotSet",
    "93a5f3c7": "InvalidWinner",
    eb783f56: "RoundMoverNotSet",
    d5881ca9: "WinnerNotSet",
    "63706563": "WinnerSetButBattleNotEnded",
  },
  app__shipBattleTakeLoot: {
    e841ff82: "BattleEndedAtNotSet",
    e9971ad4: "BattleNotEnded",
    f3b52f34: "InitiatorNotDestroyed",
    "4964fb7c": "InvalidLoserStatus",
    "4b08dceb": "InvalidWinner",
    "716ae8d5": "PlayerHasNoClaimedIsland",
    "6e9fb9f2": "ResponderNotDestroyed",
    d5881ca9: "WinnerNotSet",
  },
  app__skillProcessCompleteCreation: {
    "32d57b2f": "ProcessNotStarted",
    "9b548427": "StillInProgress",
  },
  app__skillProcessCompleteProduction: {
    fc7b9d2a: "ItemProducesIndividuals",
    "70a0bab5": "ProcessNotStarted",
    "9b548427": "StillInProgress",
  },
  app__skillProcessCompleteShipProduction: {
    "7cab0473": "BuildingExpensesNotSet",
    c4a6dde6: "InvalidRosterPlayerId",
    "2d5959ee": "ItemIdIsNotShip",
    "70a0bab5": "ProcessNotStarted",
    "2ecf8a32": "RosterUnassignedShipsNotFound",
    "9b548427": "StillInProgress",
  },
  app__skillProcessStartCreation: {
    b3479247: "LowerThanRequiredLevel",
    d1ace937: "NotEnoughEnergy",
    d9435f87: "ProcessAlreadyStarted",
  },
  app__skillProcessStartProduction: {
    fc7b9d2a: "ItemProducesIndividuals",
    b3479247: "LowerThanRequiredLevel",
    d1ace937: "NotEnoughEnergy",
    d9435f87: "ProcessAlreadyStarted",
  },
  app__skillProcessStartShipProduction: {
    fa5dbe08: "ArrayLengthMismatch",
    "01eddbe9": "ItemIdIsNotShip",
    b3479247: "LowerThanRequiredLevel",
    dc032e22: "MaterialsMismatch",
    d1ace937: "NotEnoughEnergy",
    ddc5166b: "NotEnoughMaterials",
    d9435f87: "ProcessAlreadyStarted",
  },
  ExperienceTableUtil: {
    e2612c78: "ExperienceTableNotInitialized",
  },
  FightToDeath: {
    "8919d30a": "BothAlive",
    f431d279: "BothDead",
    c1c7c84f: "InvalidOpponentHealth",
    "8083053d": "InvalidSelfHealth",
  },
  LootUtil: {
    "8f33a725": "BuildingExpensesLengthMismatch",
  },
  MapUtil: {
    ff3ba86f: "LocationNotAnIsland",
    "0610b8f7": "LocationNotFound",
  },
  PlayerInventoryUpdateUtil: {
    "88b68464": "InsufficientItemQuantity",
  },
  PlayerUtil: {
    "470013d4": "SenderIsNotPlayerOwner",
  },
  RosterDataInstance: {
    bcda426e: "EmptyRosterShipIds",
    b0dead2d: "InconsistentRosterShipId",
    a8c3b701: "InvalidRosterStatus",
    "1b498414": "InvalidRosterUpdateTime",
    b0f7a576: "IsEnvironmentRoster",
    bb8fdebc: "OriginCoordinatesNotSet",
    "716ae8d5": "PlayerHasNoClaimedIsland",
    "41145d25": "RosterIsFull",
    bbbf9b79: "RosterIslandNotCloseEnough",
    c6777196: "ShipDoesNotExist",
    "4a6f03eb": "TargetCoordinatesNotSet",
  },
  RosterSailUtil: {
    d6724682: "InvalidUpdatedCoordinates",
    "93b8004e": "RosterUnfitToSail",
  },
  RosterUtil: {
    "9e41bec1": "PlayerIsNotRosterOwner",
    "2057ac70": "RosterIsUnassignedShips",
  },
  ShipBattleUtil: {
    "65035229": "InitiatorBattleIdMismatch",
    ef0cf37c: "InitiatorIdMismatch",
    "7006e895": "InvalidSide",
    "5dfe4cd6": "NoLivingShips",
    "9e41bec1": "PlayerIsNotRosterOwner",
    "38a48130": "ResponderBattleIdMismatch",
    "56e72683": "ResponderIdMismatch",
    eb783f56: "RoundMoverNotSet",
    "479ceca0": "ShipNotFoundById",
  },
  ShipIdUtil: {
    ebb298d6: "EmptyShipIds",
    "8db9764f": "InvalidShipId",
    cdad7aa2: "ShipIdNotFound",
  },
  ShipInventoryUpdateUtil: {
    "88b68464": "InsufficientItemQuantity",
  },
  ShipUtil: {
    "0eae7169": "ShipPlayerIdMismatch",
    cc328701: "ShipRosterSequenceNumberMismatch",
  },
  SkillProcessUtil: {
    bf07c366: "IncorrectItemId",
    cd4c6603: "IncorrectSkillType",
    "2530757f": "InvalidPlayerId",
  },
  SortedVectorUtil: {
    "615fd3c0": "EmptyList",
    "331a548b": "GetItemIdQuantityPairError",
    e4bc834e: "IncorrectListLength",
    "10c7bdfc": "InsufficientQuantity",
    "672139b3": "ItemAlreadyExists",
    "031d811d": "ItemNotFound",
  },
  app__uniApiStartCreation:{
    fb8f41b2: "ERC20InsufficientAllowance",
  }
};

export const ENERGY_TOKEN_SYSTEM_ID = resourceToHex({
  type: "system",
  namespace: "app",
  name: "EnergyTokenSyste"
});
export const ENERGY_TOKEN_DROP_SYSTEM_ID = resourceToHex({
  type: "system",
  namespace: "app",
  name: "EnergyDropSystem"
});
export const EXPERIENCE_TABLE_SYSTEM_ID = resourceToHex({
  type: "system",
  namespace: "app",
  name: "ExperienceTableS",
});
export const ISLAND_CLAIM_SYSTEM_ID = resourceToHex({
  type: "system",
  namespace: "app",
  name: "IslandClaimWhite",
});
export const ITEM_CREATION_SYSTEM_ID = resourceToHex({
  type: "system",
  namespace: "app",
  name: "ItemCreationSyst",
});
export const ITEM_PRODUCTION_SYSTEM_ID = resourceToHex({
  type: "system",
  namespace: "app",
  name: "ItemProductionSy",
});
export const ROSTER_SAILING_SYSTEM_ID = resourceToHex({
  type: "system",
  namespace: "app",
  name: "RosterSailingSys",
});
export const ROSTER_SHIP_INVENTORY_SYSTEM_ID = resourceToHex({
  type: "system",
  namespace: "app",
  name: "RosterShipInvent",
});
export const SKILL_PROCESS_SYSTEM_ID = resourceToHex({
  type: "system",
  namespace: "app",
  name: "SkillProcessSyst",
});
export const SHIP_BATTLE_SYSTEM_ID = resourceToHex({
  type: "system",
  namespace: "app",
  name: "ShipBattleSystem",
});
export const SKILL_PROCESS_ID = resourceToHex({
  type: "system",
  namespace: "",
  name: "SkillProcessStartCreationLogic",
});

export const ALL_SYSTEMS = {
  ENERGY_TOKEN_SYSTEM_ID,
  EXPERIENCE_TABLE_SYSTEM_ID,
  ITEM_CREATION_SYSTEM_ID,
  ITEM_PRODUCTION_SYSTEM_ID,
  ROSTER_SAILING_SYSTEM_ID,
  ROSTER_SHIP_INVENTORY_SYSTEM_ID,
  SKILL_PROCESS_SYSTEM_ID,
  SHIP_BATTLE_SYSTEM_ID,
};
export const SKILL_PROCESS_SYSTEM_ADDRESS = '0x80eBdDaE03D21582cD163221e9a38a9d29A6e105'