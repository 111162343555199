import HarvestProductForm from "../form/HarvestProductForm";
// import Countdown from "./Countdown";
import { calculateTimeLeftInSec } from "../../../utils/tools";
import { useMUD } from "../../../useMUD";
import { useEntityQuery } from "@latticexyz/react";
import { getComponentValueStrict, Has } from "@latticexyz/recs";
import { decodeEntity } from "@latticexyz/store-sync/recs";
// import { useState, useEffect } from "react";

export function ActionQueue() {
  const {
    networkLayer: {
      components: { SkillProcess }
    },
    player
  } = useMUD();

  // const [actionQueueMenu,setActionQueueMenu] = useState<any>();
  const matchingEntities = useEntityQuery([
    Has(SkillProcess) // Get all entities with the SkillProcess component
  ]);
  
  const productQueue = matchingEntities.map((entity) => {
    const skillProcessIds = decodeEntity({"skillProcessIdSkillType": 'uint8', "skillProcessIdPlayerId": 'uint256', "skillProcessIdSequenceNumber": 'uint8'}, entity);
    const { itemId, startedAt, creationTime, completed, endedAt, batchSize, existing} = getComponentValueStrict(SkillProcess, entity);
    if(Number(skillProcessIds.skillProcessIdPlayerId) !== player?.id) return null;
    let action = '', resource = '';
    if (skillProcessIds.skillProcessIdSkillType === 0) {
      action = "Planting";
      resource = "Cotton Seeds";
    } else if (skillProcessIds.skillProcessIdSkillType === 1) {
      action = "Cutting";
      resource = "Normal Trees";
    } else if (skillProcessIds.skillProcessIdSkillType === 3) {
      action = "Mining";
      resource = "Copper Ore";
    } else if (skillProcessIds.skillProcessIdSkillType === 6) {
      action = "Crafting";
      resource = "Small Ships";
    }

    const timeLeft = calculateTimeLeftInSec(startedAt.toString(), creationTime.toString());
    return {
      ...skillProcessIds,
      itemId, 
      startedAt, 
      creationTime, 
      completed, 
      endedAt, 
      existing,
      batchSize: completed ? 0 : batchSize,
      timeLeft,
      action,
      resource,
    };
  }).filter((data) => data && data?.batchSize !==0);
  // console.log({productQueue})
  // Rosters watcher for sailing countdown
  // useEffect(() => {
  //   async function rosterWatcher() {
  //     const queue = await Promise.all(
  //       playerRosters.map(async (roster: IRoster, index) => {
  //         const action = "Sailing";
  //         const resource = `Roster ${index}`;

  //         const timeLeft = calculateTimeLeftInSec(roster.setSailAt, roster.durationLeft);

  //         return {
  //           ...roster,
  //           timeLeft,
  //           action,
  //           resource,
  //         };
  //       })
  //     );

  //     // Index 0 is the unassigned roster that we don't render
  //     // And we render those rosters are sailing currently
  //     const queueToRender = queue
  //       .slice(1)
  //       .filter(
  //         ({ status, timeLeft }) => status === ROSTER_STATUS.SAILING || (timeLeft && status === ROSTER_STATUS.BATTLING)
  //       );
  //     setSailingQueue(queueToRender);
  //   }

  //   rosterWatcher();
  // }, [playerRosters]);

  // useEffect(()=>{
  //   setActionQueueMenu(productQueue);
  // },[matchingEntities,productQueue.length]);

  if(!player) return null;

  return (
    <>
      {productQueue.length > 0 && (
        <div className="fixed top-1/2 left-2 -translate-y-1/2 w-[236px] flex flex-col items-center gap-4 bg-center bg-no-repeat bg-frame-lg bg-[length:100%_100%] px-4 py-6 z-30">
          <h1 className="text-xl text-white font-bold">Action Queue</h1>

          {productQueue.map((data) => (
            <div key={data.itemId} className="w-full flex justify-between items-center text-sm">
              <span className="text-zinc-400 line-clamp-1">{data.action}</span>
              <span className="text-white">{data.batchSize}</span>
              <span className="text-zinc-400 line-clamp-1">{data.resource}</span>

              <HarvestProductForm
                sequenceNumber={data.skillProcessIdSequenceNumber}
                skillType={data.skillProcessIdSkillType}
                processCompleted={data.completed}
                initialCount={data.timeLeft}
                startedAt={data.startedAt.toString()}
              />
            </div>
          ))}

          {/* {sailingQueue.map((data) => (
            <div key={data.id_} className="w-full flex justify-between items-center text-sm">
              <span className="text-zinc-400 line-clamp-1">Sailing</span>
              <span className="text-white line-clamp-1">{data.resource}</span>
              <div className="flex items-center gap-1">
                <Countdown initialCount={data.timeLeft} startedAt={data.setSailAt} />
                <span className="text-zinc-400 line-clamp-1">Left</span>
              </div>
            </div>
          ))} */}
        </div>
      )}
    </>
  );
}
