import { Tileset } from "../../../artTypes/world";
import { between } from "../../../utils/math";
import { seedRandom } from "../../../utils/tools";
import { PhaserLayer } from "../createPhaserLayer";

export function createMapSystem(layer: PhaserLayer) {
  const {
    scenes: {
      Main: {
        maps: {
          Main: { putTileAt },
        },
      },
    },
  } = layer;

  const seed = seedRandom();

  // CoordMap only supports coords up to 32767
  for (let x = -10; x < 10; x++) {
    for (let y = -10; y < 10; y++) {
      const seedValue = seed();
      const coord = { x: Math.floor(x * seedValue * 327.67) , y: Math.floor(y * seedValue * 327.67)};

      if (between(seedValue, 0, 0.2)) {
        putTileAt(coord, Tileset.SmallIsland1, "Foreground");
      } else if (between(seedValue, 0.4, 0.6)) {
        putTileAt(coord, Tileset.SmallIsland2, "Foreground");
      } else if (between(seedValue, 0.8, 1)) {
        putTileAt(coord, Tileset.SmallIsland3, "Foreground");
      } else { putTileAt(coord, Tileset.Ocean, "Background"); }
    }
  }
}
