// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { transactionQueue } from "@latticexyz/common/actions";
import IWorldAbi from "../../IWorld.abi.json";
import { useEffect } from "react";
import { useWalletClient } from "wagmi";
import { useStore } from "../../useStore";
import { getNetworkConfig } from "../../mud/getNetworkConfig";
import { TxToast } from "../ui/shared/TxToast";
import { AppSpinner } from "../ui/shared/AppSpinner";
import toast from "react-hot-toast";
import { useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import { useState } from "react";
import { transferErrIntoReadableMsg } from "../../utils/tools";
export type NetworkConfig = Awaited<ReturnType<typeof getNetworkConfig>>;

export type Props = {
  networkConfig: NetworkConfig;
  children: React.ReactNode;
};

export function ExternalWalletProvider({ networkConfig, children }: Props) {
  //cache the callback function to execute on success or error
  const [callback, setCallback] = useState();
  const { data: externalWalletClient } = useWalletClient();
  const {
    writeContract,
    data: hash,
    error,
    isPending,
    reset,
  } = useWriteContract()

  const {
    isLoading: isConfirming,
    isSuccess,
    error: txError,
    data: receipt,
  } = useWaitForTransactionReceipt({ hash })

  useEffect(() => {
    if (!externalWalletClient) {
      useStore.setState({ externalWalletClient: null, writeWorldContract: null });
      return;
    }

    const customExternalWalletClient = externalWalletClient.extend(transactionQueue());
    const writeWorldContract = (funName: string, args: any[], callback = () => {}) => {
      setCallback(() => callback);
      console.log({args});
      
      writeContract({
        address: networkConfig.worldAddress as Hex,
        abi: IWorldAbi,
        functionName: funName,
        args,
        account: customExternalWalletClient.account,
      })
    }
    
    useStore.setState({ externalWalletClient: customExternalWalletClient, writeWorldContract });
  }, [externalWalletClient, networkConfig.chain, networkConfig.worldAddress]);

  useEffect(() => {
    
    
    if (error) {
      // console.log(error,"error name", error.cause);
      console.log(transferErrIntoReadableMsg(error.message));
      toast.custom(
        <TxToast
          title="Failed from wallet"
          description={transferErrIntoReadableMsg(error.message)}
          status="failed"
        />,
        { id: "wallet-failed" },
      )
      callback({status: false, error: transferErrIntoReadableMsg(error.message)});
    }
    if (isPending) {
      toast.custom(
        <TxToast
          title="Confirming transaction"
          logo={<AppSpinner wrapperWidth="w-fit" />}
          description="Waiting to confirm"
          status="pending"
        />,
        { id: "wallet-pending" },
      )
    }

    if (txError) {
      toast.custom(
        <TxToast
          title="Failed to Execute"
          description={transferErrIntoReadableMsg(txError.message)}
          status="failed"
        />,
        { id: "failed" },
      )
      callback({status: false, error: transferErrIntoReadableMsg(txError.message)});
    }
    if (isConfirming) {
      toast.custom(
        <TxToast
          title="Pending Network"
          logo={<AppSpinner wrapperWidth="w-fit" />}
          description="Getting transaction..."
          status="pending"
        />,
        { id: "confirming" },
      )
    }
    if (isSuccess) {
      toast.custom(
        <TxToast
          title='Executed'
          description={
            <a
              target="_blank"
              rel="noreferrer"
              className="underline"
              href={`https://testnet.storyscan.xyz/tx/${
                (isSuccess && (receipt as any)?.transactionHash) || ""
              }`}
            />
          }
          status="success"
        />,
        { id: "confirmed" },
      )
      callback({status: true, receipt});

      //reset time after 2 sec
      setTimeout(() => {
        reset();
      }, 2000)
    }
  }, [error, isConfirming, isPending, isSuccess, receipt, reset, txError])

  return children;
}
