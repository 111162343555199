import { Client, Provider, cacheExchange, fetchExchange } from "urql";

import React from "react";

const client = new Client({
  url: import.meta.env.VITE_SUBGRAPH_URL,
  exchanges: [cacheExchange, fetchExchange],
});

export default function SubgraphProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <Provider value={client}>{children}</Provider>;
}
